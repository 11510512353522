import React from 'react'
import { connect } from 'react-redux'
import { Router as RRouter, Route, Redirect, Switch } from 'react-router-dom'
import { ScrollContext } from 'react-router-scroll-4'
import { LoginPageContainer } from 'features/LoginPage'
import { LandingPage } from 'features/LandingPage'
import { NotDoneYetPage } from 'features/NotDoneYetPage'
import { SettingsPageContainer } from 'features/SettingsPage'
import { AdminPage } from 'features/AdminPage'
import { StyleguidePage } from 'features/StyleguidePage'
import { ForgotPasswordPage } from 'features/ForgotPasswordPage'
import { NotFoundPage } from 'features/NotFoundPage'
import { NewMapPageContainer } from 'features/NewMapPage'
import { Onboarding } from 'features/Onboarding'
import { authSelectors } from 'reducers/auth'
import { history } from 'lib/history'
import { Helmet } from 'react-helmet'
import { LoadableSiteListPage } from 'features/SiteListPage'
import { SiteDetailPage } from 'features/SiteDetailPage'
import { DetectionLogPage } from 'features/DetectionLogPage'

// NOTE - if you're getting errors like
//
// "Failed prop type: Invalid prop `component` of type `object` supplied to `Route`, expected `function`.
// in Route (at Router.js:33)""
//
// it's usually because a component is wrapped in a React.memo call. This doesn't break anything because
// the rendering engine knows how to play nice with this object notation, but it does trigger the dumb
// Failed Prop Type errors and there's nothing you can do about it without eating the stupid
// React Router upgrade tax

// TODO - use a fragment here to avoid having to manually manage keys
// https://github.com/ReactTraining/react-router/issues/5785#issuecomment-359427800
const commonRoutes = [
  <Route key="forgot" path="/forgot" component={ForgotPasswordPage} />,
  <Route key="styleguide" path="/styleguide" component={StyleguidePage} />,
  <Route key="landing" path="/landing" component={LandingPage} />,
  <Route key="oops" path="/oops" component={NotDoneYetPage} />,
]

const PublicRoutes = (
  <Switch>
    <Route path="/signup" component={LoginPageContainer} />
    <Route path="/login" component={LoginPageContainer} />
    {commonRoutes}
    {/* <Redirect to='/' /> TODO figure out how to redirect to login for all auth required routes! */}
    {/* Catch-all 404 page route */}
    <Redirect exact from="/" to="/login" />
    <Route component={NotFoundPage} />
  </Switch>
)

// So, we're entering the part of the development where a route MIGHT render a
// 404 based on the response from the API.
//
// For example, loading up /maps/2345 will render a 404 if map #2345 isn't public
//
// this is something that would potentially be interesting to roll-your-own,
// BUT you should probably check out react-loadable first

const LoggedInRoutes = (
  <Switch>
    <Route path="/welcome" component={Onboarding} />
    <Route exact path="/" component={LoadableSiteListPage} />
    <Route exact path="/detections" component={DetectionLogPage} />
    <Route path="/sites/:siteId" component={SiteDetailPage} />
    <Route path="/settings" component={SettingsPageContainer} />
    <Route path="/admin" component={AdminPage} />
    <Route path="/profile" component={NotDoneYetPage} />
    <Route path="/maps/new" component={NewMapPageContainer} />
    {commonRoutes}
    <Redirect from="/signup" to="/" />
    <Redirect from="/login" to="/" />
    {/* Catch-all 404 page route */}
    <Route component={NotFoundPage} />
  </Switch>
)

export class Router extends React.PureComponent {
  render() {
    const { loggedIn } = this.props
    return (
      <RRouter history={history}>
        <ScrollContext>
          <div>
            <Helmet
              defaultTitle="trailhead.club"
              titleTemplate="%s · trailhead.club"
            />
            {loggedIn ? LoggedInRoutes : PublicRoutes}
          </div>
        </ScrollContext>
      </RRouter>
    )
  }
}

const mapStateToProps = (state) => ({
  loggedIn: authSelectors.loggedIn(state),
})

export const RouterContainer = connect(mapStateToProps)(Router)

import React from 'react'
import styles from './Layout.module.scss'
import { NavbarContainer } from 'common/Navbar/'
import { Footer } from 'common/Footer/'
import { ToastOverlayContainer } from 'features/ToastOverlay'
import { ModalOverlayContainer } from 'features/ModalOverlay'

export class Layout extends React.PureComponent {
  render() {
    const { children, dark, noBorder } = this.props
    return (
      <div className={styles.Layout}>
        <NavbarContainer noShadow noBorder={noBorder} />
        <div className={styles.main}>{children}</div>
        <Footer dark={dark} />
        <ToastOverlayContainer />
        <ModalOverlayContainer />
      </div>
    )
  }
}

import React from 'react'
import styles from './StyleguidePage.module.scss'
import { LayoutNarrow } from 'common/layouts/LayoutNarrow'
import { Button } from 'common/Button'
import { Table } from 'common/Table'
import * as icons from 'common/icons'
import { SuccessSpinner, CircleSpinner } from 'common/spinners'
import cx from 'classnames'
import { Helmet } from 'react-helmet'

export class StyleguidePage extends React.PureComponent {
  render() {
    return (
      <LayoutNarrow>
        <Helmet title="Styleguide" />
        <div className={styles.StyleguidePage}>
          <Section title="Typography">
            <h1>Heading 1</h1>
            <h2>Heading 2</h2>
            <h2 data-alt>Heading 2 (alternate)</h2>
            <h3>Heading 3</h3>
            <h4>Heading 4</h4>
            <h5>Heading 5</h5>
            <p>
              Paragraph lorem ipsum dolor sit amet, <strong>bold</strong>{' '}
              consectetur adipiscing elit,{' '}
              <a href="https://example.com">paragraph link</a> sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim veniam, quis nostrud exercitation ullamco laboris nisi ut
              aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </Section>

          <Section title="Color">
            <h2>Brand Colors</h2>
            <Swatch data-primary label="Primary" />
            <Swatch data-accent-secondary label="Accent Secondary " />
            <Swatch data-accent-tertiary label="Accent Tertiary" />
            <Swatch data-accent label="Accent" />

            <h2>Semantic Colors</h2>
            <Swatch data-danger label="Danger" />
            <Swatch data-success label="Success" />

            <h2>Text Colors</h2>
            <Swatch className={styles.textLighter} label="Text Lighter" />
            <Swatch className={styles.textLight} label="Text Light" />
            <Swatch className={styles.textSubtle} label="Text Subtle" />
            <Swatch className={styles.textNormal} label="Text Normal" />
            <Swatch className={styles.textDark} label="Text Dark" />

            <h2>UI Colors</h2>
            <Swatch className={styles.borderColor} label="Border Normal" />
          </Section>

          <Section title="Buttons">
            <h2>Button Variants</h2>
            <Table className={styles.buttons} style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <strong>Standard Button</strong>
                  </th>
                  <th>
                    <strong>Primary Button</strong>
                  </th>
                  <th>
                    <strong>Danger Button</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={styles.btnLabel}>Normal</td>
                  <td>
                    <Button>Button</Button>
                  </td>
                  <td>
                    <Button primary>Button Primary</Button>
                  </td>
                  <td>
                    <Button danger>Button Danger</Button>
                  </td>
                </tr>
                <tr>
                  <td className={styles.btnLabel}>Hover</td>
                  <td>
                    <Button hover>Button</Button>
                  </td>
                  <td>
                    <Button hover primary>
                      Button Primary
                    </Button>
                  </td>
                  <td>
                    <Button hover danger>
                      Button Danger
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td className={styles.btnLabel}>Active</td>
                  <td>
                    <Button active>Button</Button>
                  </td>
                  <td>
                    <Button active primary>
                      Button Primary
                    </Button>
                  </td>
                  <td>
                    <Button active danger>
                      Button Danger
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td className={styles.btnLabel}>Disabled</td>
                  <td>
                    <Button disabled>Button</Button>
                  </td>
                  <td>
                    <Button disabled primary>
                      Button Primary
                    </Button>
                  </td>
                  <td>
                    <Button disabled danger>
                      Button Danger
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table className={styles.buttons} style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <strong>Filled Button</strong>
                  </th>
                  <th>
                    <strong>Filled Primary</strong>
                  </th>
                  <th>
                    <strong>Filled Danger</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={styles.btnLabel}>Normal</td>
                  <td>
                    <Button filled>Button</Button>
                  </td>
                  <td>
                    <Button filled primary>
                      Button Primary
                    </Button>
                  </td>
                  <td>
                    <Button filled danger>
                      Button Danger
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td className={styles.btnLabel}>Hover</td>
                  <td>
                    <Button filled hover>
                      Button
                    </Button>
                  </td>
                  <td>
                    <Button filled hover primary>
                      Button Primary
                    </Button>
                  </td>
                  <td>
                    <Button filled hover danger>
                      Button Danger
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td className={styles.btnLabel}>Active</td>
                  <td>
                    <Button filled active>
                      Button
                    </Button>
                  </td>
                  <td>
                    <Button filled active primary>
                      Button Primary
                    </Button>
                  </td>
                  <td>
                    <Button filled active danger>
                      Button Danger
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td className={styles.btnLabel}>Disabled</td>
                  <td>
                    <Button filled disabled>
                      Button
                    </Button>
                  </td>
                  <td>
                    <Button filled disabled primary>
                      Button Primary
                    </Button>
                  </td>
                  <td>
                    <Button filled disabled danger>
                      Button Danger
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            <h2>More States</h2>
            <LoadingDemo /> <Button accent>Accent Color</Button>{' '}
            <Button accent filled>
              Accent Filled
            </Button>{' '}
            <Button primary outline>
              Outline
            </Button>
          </Section>

          <Section title="Spinners">
            <h2>Circle Spinner</h2>
            <p>
              <CircleSpinner data-primary inline /> Loading...
            </p>
            <h2>Success Spinner</h2>
            <p>
              <SuccessDemo />
            </p>
          </Section>

          <Section title="Icons">
            <h2>All Icons</h2>
            <div className={styles.allIcons}>
              {Object.keys(icons).map((icon) => {
                const Icon = icons[icon]
                return (
                  <div className={styles.iconDemo} key={icon}>
                    <Icon inline /> {icon}
                  </div>
                )
              })}
            </div>
          </Section>
        </div>
      </LayoutNarrow>
    )
  }
}

class LoadingDemo extends React.PureComponent {
  state = {
    loading: true,
  }

  load = () => {
    this.setState({ loading: !this.state.loading })
  }

  render() {
    const { loading } = this.state
    return (
      <Button filled primary loading={loading} onClick={this.load}>
        Toggle Loading State
      </Button>
    )
  }
}

class SuccessDemo extends React.PureComponent {
  state = {
    success: true,
  }
  toggle = () => {
    this.setState({ success: !this.state.success })
  }
  render() {
    const { success } = this.state
    return (
      <>
        {success && <SuccessSpinner inline />} Success!{' '}
        <Button onClick={this.toggle}>Toggle</Button>
      </>
    )
  }
}

const Swatch = ({ className, label, ...props }) => (
  <div
    className={cx(styles.Swatch, className)}
    {...props}
    data-tip
    data-tippy-placement="bottom"
    title={label}
  >
    &nbsp;
    <span className={styles.label}>{label}</span>
  </div>
)

const Section = ({ title, children }) => (
  <div className={styles.Section}>
    <div className={styles.sectionTitle}>{title}</div>
    {children}
  </div>
)

import React from 'react'
import { Link } from 'react-router-dom'
import styles from './SiteSummaryCard.module.scss'
import {
  OpenOutlineIcon,
  NotificationsIcon,
  CarIcon,
  PinIcon,
  SunnyIcon,
  MoonIcon,
  KeypadIcon,
} from 'common/icons'
import * as dateFormats from 'lib/date-formats'
import { day } from 'lib/day'
import { Button } from 'common/Button'
import { authSelectors } from 'reducers/auth'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import { api } from 'api'
import { useMutation } from 'react-query'
import { queryClient } from 'app/queryClient'
import { modalTypes } from 'reducers/modal'
import { useShowModal } from 'lib/useShowModal'

const fmt = (d) => day(d).utc().format(dateFormats.short)

const siteTypeBadge = (siteType) => {
  switch (siteType) {
    case 'permit-day-use':
      return (
        <>
          <SunnyIcon inline /> Day Use
        </>
      )
    case 'permit-overnight':
      return (
        <>
          <MoonIcon inline /> Overnight Permit
        </>
      )
    case 'campsite':
      return (
        <>
          <PinIcon inline /> Campsite
        </>
      )
    case 'campground':
      return (
        <>
          <KeypadIcon inline /> Campground
        </>
      )
    default:
      console.warn(`unknown type ${siteType}`)
      return ''
  }
}

export const SiteSummaryCard = ({ site }) => {
  const { name, description, nextAvailable } = site
  const showModal = useShowModal()
  const background = `radial-gradient(#c5f6fa, #3bc9db`

  // todo wrap this in a custom hook
  const token = useSelector(authSelectors.token)
  const user = useSelector(authSelectors.currentUser)
  const toggleWatching = useMutation(
    () =>
      site.watching
        ? api.sites.unwatch(token, site.id)
        : api.sites.watch(token, site.id).then(({ watch }) => {
            showModal(modalTypes.EDIT_WATCH, { watch, site })
          }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('SITES')
      },
    },
  )

  const highFrequency = false // FIXME, TODO

  return (
    <div
      className={cx(
        styles.SiteSummaryCard,
        !nextAvailable && styles.noAvailableDates,
      )}
    >
      <div className={styles.img} style={{ background }}>
        {site.watching && (
          <div className={styles.watchingIcon}>
            <NotificationsIcon inline /> Watching{' '}
            {highFrequency ? '(High Frequency)' : ''}
          </div>
        )}
        <div className={styles.siteIcon}>{site.emoji}</div>
      </div>
      <div className={styles.inner}>
        <div className={styles.availability}>
          {/*<span title={lastScan?.finishedAt}>
            Last scanned {relativeScanTime}
          </span>*/}
          {site.pauseScans && (
            <div className={styles.pauseScansIcon}>SCANS PAUSED</div>
          )}
          {!site.pauseScans &&
            (nextAvailable ? (
              <>Next available {fmt(nextAvailable)}</>
            ) : (
              'No available dates'
            ))}
        </div>
        <div className={styles.title}>
          <Link to={`/sites/${site.id}`} className={styles.cardLink}>
            {name}
          </Link>
        </div>
        <div className={styles.details}>
          <div className={styles.detail}>{siteTypeBadge(site.siteType)}</div>
          {user.latLng && (
            <>
              <div className={styles.detail}>
                <PinIcon inline /> {Math.round(site.distance)} mi
              </div>
              {site.driveTime?.duration?.value && (
                <div className={styles.detail}>
                  <CarIcon inline />{' '}
                  {day
                    .duration(site.driveTime.duration.value * 1000)
                    .format('H:mm')}{' '}
                  hr
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <div>
          {site.nextAvailable && (
            <Button
              href={description}
              target="_blank"
              rel="noopener noreferrer"
              outline
              primary
            >
              <OpenOutlineIcon inline /> Reserve
            </Button>
          )}
        </div>
        <div>
          <Button
            onClick={toggleWatching.mutate}
            loading={toggleWatching.isLoading}
          >
            {site.watching ? 'Stop Watching' : 'Add Watch'}
          </Button>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import { Helmet } from 'react-helmet'
import { LayoutNarrow } from 'common/layouts/LayoutNarrow'
import styles from './ErrorPage.module.scss'
import { ArrowBackIcon } from 'common/icons'
import { Button } from 'common/Button'
import cx from 'classnames'

export class ErrorPage extends React.PureComponent {
  render() {
    const { title, detail, error, className } = this.props
    return (
      <LayoutNarrow>
        <Helmet title={title} />
        <div className={cx(styles.ErrorPage, className)}>
          {title && <h1>{title}</h1>}
          {detail && <p>{detail}</p>}
          <p>
            {/* TODO display a back button instead of a home button if we know
                that they came from another page on the site */}
            {/* TODO use transparency instead of lightening to make buttons
                look good on a non-white bg */}
            <Button primary to="/">
              <ArrowBackIcon inline />
              Home
            </Button>
          </p>
          {process.env.NODE_ENV !== 'production' && error && (
            <pre>{JSON.stringify(error, null, '  ')}</pre>
          )}
        </div>
      </LayoutNarrow>
    )
  }
}

import React from 'react'
import cx from 'classnames'
import styles from './Footer.module.scss'

const YEAR = new Date().getFullYear()

export class Footer extends React.PureComponent {
  render() {
    const { dark } = this.props

    return (
      <div className={cx(styles.Footer, dark && styles.dark)}>
        <div className={styles.inner}>
          <div>Copyright &copy; {YEAR} trailhead.club</div>
          <div>
            <a href="mailto:qr@qrohlf.com">Questions?</a>
          </div>
        </div>
      </div>
    )
  }
}

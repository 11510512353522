import React from 'react'
import { LayoutNarrow } from 'common/layouts/LayoutNarrow'
import styles from './SettingsPage.module.scss'
import { connect } from 'react-redux'
import { authSelectors } from 'reducers/auth'
import { Button } from 'common/Button'
import { ChangePasswordContainer } from './ChangePassword'
import { Setting, SettingsSection } from 'common/settings'
import { Helmet } from 'react-helmet'

export class SettingsPage extends React.PureComponent {
  render() {
    const { currentUser } = this.props
    return (
      <LayoutNarrow>
        <Helmet title="Settings" />
        <div className={styles.SettingsPage}>
          <h1>Settings</h1>
          <SettingsSection title="Login">
            <ChangeEmail currentUser={currentUser} />
            <Setting
              title="Phone Number"
              action={
                <Button primary to="/oops">
                  Change Phone Number
                </Button>
              }
            >
              Notifications will be sent to {currentUser.phoneNumber}
            </Setting>
            <ChangePasswordContainer />

            <Setting
              title="Forgot Password"
              action={
                <Button primary to="/oops">
                  Send Email
                </Button>
              }
            >
              Reset your password via email
            </Setting>
          </SettingsSection>

          <SettingsSection title="Display">
            <Setting
              title="Units"
              action={
                <Button primary to="/oops">
                  Switch to Metric
                </Button>
              }
            >
              Using Imperial units (feet/miles)
            </Setting>

            <Setting
              title="Time"
              action={
                <Button primary to="/oops">
                  Switch to DD/MM/YY
                </Button>
              }
            >
              Using MM/DD/YY
            </Setting>
          </SettingsSection>

          <SettingsSection title="Emails">
            <Setting
              title="SMS Notifications"
              action={
                <Button primary to="/oops">
                  Disable
                </Button>
              }
            >
              We'll send you a text message when we find newly available dates
              for sites that you're watching.
            </Setting>

            <Setting
              title="Site News"
              action={
                <Button primary to="/oops">
                  Enable
                </Button>
              }
            >
              Enable this setting to hear recieve updates when we release new
              features and content.
            </Setting>
          </SettingsSection>
        </div>
      </LayoutNarrow>
    )
  }
}

class ChangeEmail extends React.PureComponent {
  render() {
    const { currentUser } = this.props
    return (
      <Setting
        title="Email"
        action={
          <Button primary to="/oops">
            Change Email
          </Button>
        }
      >
        Your current email is {currentUser.email}
      </Setting>
    )
  }
}

const mapStateToProps = (state) => ({
  currentUser: authSelectors.currentUser(state),
})

export const SettingsPageContainer = connect(mapStateToProps)(SettingsPage)

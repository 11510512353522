import React from 'react'
import { LayoutNarrow } from 'common/layouts/LayoutNarrow'
import { Setting, SettingsSection } from 'common/settings'
import { Link } from 'react-router-dom'
import styles from './AdminLandingPage.module.scss'

export class AdminLandingPage extends React.PureComponent {
  render() {
    return (
      <LayoutNarrow>
        <div className={styles.AdminLandingPage}>
          <h1>Admin</h1>
          <SettingsSection title="Users">
            <Setting title={<Link to="/admin/users/new">New User</Link>}>
              Create a new user account
            </Setting>
            <Setting title={<Link to="/admin/users">Manage Users</Link>}>
              Manage users and roles
            </Setting>
            <Setting title={<Link to="/admin/invites">Manage Beta</Link>}>
              Grant beta permissions to new users
            </Setting>
          </SettingsSection>
          <SettingsSection title="Logs">
            <Setting title={<Link to="/admin/task_logs">Tasks</Link>}>
              Task run logs
            </Setting>
            <Setting title={<Link to="/detections">Detections</Link>}>
              Availability detection logs
            </Setting>
          </SettingsSection>
          <SettingsSection title="Misc">
            <Setting
              title={
                <a
                  href="https://cloud.digitalocean.com/droplets/237680380/graphs?i=2a062b&period=hour"
                  target="_blank"
                  rel="noreferrer"
                >
                  Server Monitoring
                </a>
              }
            >
              Backend server monitoring dashboard
            </Setting>
            <Setting title={<Link to="/styleguide">Styleguide</Link>}>
              Font, color, and UI element reference
            </Setting>
          </SettingsSection>
        </div>
      </LayoutNarrow>
    )
  }
}

import React from 'react'
import { useSessionState } from 'lib/useSessionState'
import styles from './TaskLogsPage.module.scss'
import { LayoutNarrow } from 'common/layouts/LayoutNarrow'
import { TaskRunsTable } from 'common/TaskRunsTable'
import { Helmet } from 'react-helmet'
import { authSelectors } from 'reducers/auth'
import { useSelector } from 'react-redux'
import { api } from 'api'
import { useQuery } from 'react-query'
import { LoadableQueryPage } from 'common/LoadableQueryPage'

export const TaskLogsPage = (props) => {
  const token = useSelector(authSelectors.token)
  // refetch every 30s
  const taskRunsQuery = useQuery('TASK_RUNS', () => api.taskRuns.index(token), {
    refetchInterval: 30 * 1000,
  })
  const taskRuns = taskRunsQuery.data?.taskRuns
  return (
    <LoadableQueryPage
      query={taskRunsQuery}
      Page={TaskLogsLoaded}
      taskRuns={taskRuns}
    />
  )
}

const TaskLogsLoaded = ({ taskRuns }) => {
  const [filterHF, setFilterHF] = useSessionState('task-logs/filterHF', false)

  const _taskRuns = filterHF
    ? taskRuns.filter((run) => run.name !== 'scan-high-frequency')
    : taskRuns

  return (
    <LayoutNarrow>
      <Helmet title="Tasks" />
      <div className={styles.header}>
        <h1>Tasks</h1>
        <label>
          <input
            type="checkbox"
            checked={filterHF}
            onChange={() => setFilterHF(!filterHF)}
          />{' '}
          Hide HF scans
        </label>
      </div>
      <TaskRunsTable taskRuns={_taskRuns} />
    </LayoutNarrow>
  )
}

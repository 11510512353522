import React from 'react'
import styles from './Button.module.scss'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { CircleSpinner } from 'common/spinners'
import { FadeInOut } from 'common/animations'
import _ from 'lodash'

export class Button extends React.PureComponent {
  render() {
    const {
      className,
      children,
      loading,
      to,
      href,
      active,
      filled,
      ...props
    } = this.props
    const classNameProps = Object.keys(styles).filter(
      (cn) => props[cn] !== undefined,
    )
    const safeProps = _.omit(props, Object.keys(styles))
    const cn = cx(
      styles.Button,
      active && styles.active,
      classNameProps.map((k) => styles[k]),
      loading && styles.loading,
      filled && styles.filled,
      className,
    )

    const content = (
      <>
        <FadeInOut
          in={loading}
          timing={{ enter: 300, exit: 200 }}
          delay={{ enter: 75, exit: 0 }}
        >
          <CircleSpinner className={styles.spinner} />
        </FadeInOut>{' '}
        {children}
      </>
    )

    if (to) {
      return (
        <Link to={to} className={cn} {...safeProps}>
          {content}
        </Link>
      )
    }

    if (href) {
      return (
        <a href={href} className={cn} {...safeProps}>
          {content}
        </a>
      )
    }

    return (
      <button className={cn} {...safeProps}>
        {content}
      </button>
    )
  }
}

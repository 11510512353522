import React from 'react'
import { connect } from 'react-redux'
import { NavLink, Link } from 'react-router-dom'
import { Route } from 'react-router'
import { Layout } from 'common/layouts/Layout'
import { FormControl } from 'common/FormControl/'
import { FormError } from 'common/forms'
import { Button } from 'common/Button'
import { urls } from 'lib/urls'
import { parseQuery } from 'lib/utils'
import { authActions } from 'reducers/auth'
import styles from './LoginPage.module.scss'

export class LoginPage extends React.PureComponent {
  state = {
    error: null,
  } // TODO - loading spinner on button

  onSubmitLogin = ({ email, password }) => {
    const { history, doLogin } = this.props
    this.setState({ error: null })
    return doLogin({ email, password })
      .then(() => history.push(urls.viewMap()))
      .catch(this.onError)
  }

  onSignup = ({ email, password }) => {
    const { history, doSignup } = this.props
    this.setState({ error: null })
    return doSignup({ email, password }).then(() =>
      history.push(urls.onboarding()),
    )
  }

  render() {
    return (
      <Layout>
        <div className={styles.LoginPage}>
          <FloatingBox>
            <div className={styles.header}>
              <NavLink
                className={styles.headerLink}
                activeClassName={styles.active}
                to="/signup"
              >
                Sign Up
              </NavLink>
              <NavLink
                className={styles.headerLink}
                activeClassName={styles.active}
                to="/login"
              >
                Log In
              </NavLink>
            </div>
            <Route
              path="/signup"
              render={(props) => (
                <SignupForm {...props} onSubmitLogin={this.onSignup} />
              )}
            />
            <Route
              path="/login"
              render={(props) => (
                <LoginForm {...props} onSubmitLogin={this.onSubmitLogin} />
              )}
            />
          </FloatingBox>
        </div>
      </Layout>
    )
  }
}

export const LoginPageContainer = connect(null, {
  doLogin: authActions.login,
  doSignup: authActions.signup,
})(LoginPage)

// TODO refactor SignupForm and LoginForm to have less duplicated code!
class SignupForm extends React.PureComponent {
  constructor(props) {
    super(props)
    const { email } = parseQuery(props.location.search)
    this.state = {
      email: email || '',
      password: '',
    }
  }

  onChangeEmail = (e) => {
    this.setState({ email: e.target.value })
  }

  onChangePassword = (e) => {
    this.setState({ password: e.target.value })
  }

  onSubmit = (e) => {
    const { email, password } = this.state
    e.preventDefault()
    if (!email) {
      this.setState({ error: 'Please enter an email address' })
      return
    }
    this.setState({ error: null, loading: true })
    this.props.onSubmitLogin({ email, password }).catch(this.onError)
  }

  onError = (e) => {
    if (e.error) {
      this.setState({ error: e.error, loading: false })
      return
    }
    this.setState({
      error: `We're having trouble connecting to the server, please try again later.`,
      loading: false,
    })
  }

  render() {
    const { email, password, error, loading } = this.state

    return (
      <div className={styles.content}>
        <h2>Create a free account to get started</h2>
        <form className={styles.form} onSubmit={this.onSubmit}>
          <FormControl>
            <label>Email</label>
            <input type="email" value={email} onChange={this.onChangeEmail} />
          </FormControl>
          <FormControl>
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={this.onChangePassword}
            />
          </FormControl>
          <FormError error={error} />
          <Button block filled loading={loading} primary type="submit">
            Sign Up
          </Button>
        </form>
      </div>
    )
  }
}

class LoginForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
    }
  }

  onChangeEmail = (e) => {
    this.setState({ email: e.target.value })
  }

  onChangePassword = (e) => {
    this.setState({ password: e.target.value })
  }

  onSubmit = (e) => {
    const { email, password } = this.state
    e.preventDefault()
    if (!email) {
      this.setState({ error: 'Please enter an email address' })
      return
    }
    this.setState({ error: null, loading: true })
    this.props.onSubmitLogin({ email, password }).catch(this.onError)
  }

  onError = (e) => {
    if (e.error) {
      this.setState({ error: e.error, loading: false })
      return
    }
    this.setState({
      error: `We're having trouble connecting to the server, please try again later.`,
      loading: false,
    })
  }

  render() {
    const { email, password, error, loading } = this.state

    return (
      <div className={styles.content}>
        <h2>Welcome back!</h2>
        <form className={styles.form} onSubmit={this.onSubmit}>
          <FormControl>
            <label>Email</label>
            <input type="email" value={email} onChange={this.onChangeEmail} />
          </FormControl>
          <FormControl>
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={this.onChangePassword}
            />
          </FormControl>
          <FormError error={error} />
          <Button block filled loading={loading} primary type="submit">
            Log In
          </Button>
          <div
            style={{ textAlign: 'center', marginTop: '10px', fontSize: '12px' }}
          >
            <Link to="/forgot">Forgot Password?</Link>
          </div>
        </form>
      </div>
    )
  }
}

const FloatingBox = ({ children }) => (
  <div className={styles.FloatingBox}>{children}</div>
)

export const SignupPage = (props) => <LoginPage signup {...props} />

const todayDateString = () => new Date().toISOString().slice(0, 10)

export const getAvailabilityGridUrl = (site, startDate = todayDateString()) => {
  const { scanType, scanParams } = site
  const path = (() => {
    switch (scanType) {
      // known issues with the rec.gov permit booking interface
      // - if the season has not yet started for the permit, rec.gov will
      //   clobber the start_date param with the first date of the permit season
      case 'permit':
      case 'permit-composite':
      case 'permitinyo':
        return `permits/${scanParams.permitId}/registration/detailed-availability?date=${startDate}&type=overnight-permit`
      // known issues with the rec.gov campground booking interface
      // - instead of using standard YYYY-MM-DD dates, this interface relies on
      //   "checkin" and "checkout" fields that live in redux and are formatted
      //   as MM/DD/YYY
      // - even when populated into redux via the search UI, the code on the
      //   CampgroundPage that is supposed to pull these out of the Redux
      //   store in order to pre-seed the availability grid appears to be broken
      // - so, as of right now, we don't appear to have a way to set the start
      //   date for a campground availability calendar via the URL or via
      //   UI automation. Womp womp.
      case 'campground':
        return `camping/campgrounds/${scanParams.campgroundId}/availability`
      default:
        return null
    }
  })()

  return path ? `https://www.recreation.gov/${path}` : site.description
}

import React from 'react'
import { Popover } from 'common/Popover'
import { Link } from 'react-router-dom'
import styles from './PopoverMenu.module.scss'
import cx from 'classnames'

const MenuList = ({ dismissPopover, children }) => {
  return (
    <ul className={styles.PopoverMenu}>
      {React.Children.map(
        children,
        (c) => c && React.cloneElement(c, { dismissPopover }),
      )}
    </ul>
  )
}

export class PopoverMenu extends React.PureComponent {
  render() {
    const { children, className, ...props } = this.props
    return (
      <Popover className={cx(styles.PopoverOuter, className)} {...props}>
        <MenuList>{children}</MenuList>
      </Popover>
    )
  }
}

// TODO close popover menu when an item is clicked (unless the item's onclick handler returns true!)
export class PopoverMenuItem extends React.PureComponent {
  render() {
    const {
      icon: Icon,
      dismissPopover,
      closePopoverOnClick,
      children,
      to,
      href,
      target,
      rel,
      ...props
    } = this.props

    const onClick = closePopoverOnClick
      ? (e) => {
          props.onClick(e)
          dismissPopover()
        }
      : props.onClick

    if (to) {
      return (
        <li {...props} onClick={onClick}>
          <Link className={styles.PopoverMenuItem} to={to}>
            {Icon && (
              <div className={styles.icon}>
                {typeof Icon === 'string' ? Icon : <Icon inline />}
              </div>
            )}{' '}
            {children}
          </Link>
        </li>
      )
    }

    if (href) {
      return (
        <li {...props} onClick={onClick}>
          <a
            className={styles.PopoverMenuItem}
            href={href}
            target={target}
            rel={rel}
          >
            {Icon && (
              <div className={styles.icon}>
                {typeof Icon === 'string' ? Icon : <Icon inline />}
              </div>
            )}{' '}
            {children}
          </a>
        </li>
      )
    }

    return (
      <li className={styles.PopoverMenuItem} {...props} onClick={onClick}>
        {Icon && (
          <div className={styles.icon}>
            {typeof Icon === 'string' ? Icon : <Icon inline />}
          </div>
        )}{' '}
        {children}
      </li>
    )
  }
}

export class PopoverMenuDivider extends React.PureComponent {
  render() {
    const { className, ...props } = this.props
    return <li className={cx(styles.divider, className)} {...props} />
  }
}

import { useMutation } from 'react-query'
import { useAuthenticatedRequest } from 'lib/useAuthenticatedRequest'
import { queryClient } from 'app/queryClient'

export const useUpdateWatch = () => {
  const { patch } = useAuthenticatedRequest()
  return useMutation(
    ({ watchId, updates }) => patch(`/watches/${watchId}`, { watch: updates }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('SITES')
      },
    },
  )
}

export const useDeleteWatch = () => {
  const { _delete } = useAuthenticatedRequest()
  return useMutation(({ watchId }) => _delete(`/watches/${watchId}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('SITES')
    },
  })
}

import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { reducer } from 'reducers/root'
import { authActions, authSelectors } from 'reducers/auth'
import { toastMiddleware } from 'middleware/toastMiddleware'

const getStore = () => {
  // setup redux
  console.log('STORE_INIT: localStorage contents', getPersistedState())
  const initialState = getPersistedState()
  console.log('STORE_INIT: initialState', initialState)
  const store = createStore(
    reducer,
    initialState,
    applyMiddleware(thunk, logger, toastMiddleware),
  )
  window.store = store
  console.log('STORE_INIT: state after applying reducers', store.getState())

  store.subscribe(() => persistState(store.getState()))
  fetchInitialData(store)
  return store
}

const fetchInitialData = (store) => {
  const state = store.getState()
  if (authSelectors.loggedIn(state)) {
    store.dispatch(authActions.refreshCurrentUser())
  }
}

// TODO - figure out how to avoid persisting the state on *every* keystroke!
const persistState = (state) => {
  try {
    const serialized = reducer.serialize(state)
    console.log('persistState', serialized)
    window.localStorage.setItem('state', JSON.stringify(serialized))
  } catch (e) {
    console.error('error serializing state')
    console.error(e)
  }
}

const getPersistedState = () =>
  reducer.deserialize(JSON.parse(window.localStorage.getItem('state')) || {})

// TODO - reset ALL localstorage on logout to ensure a clean state
window.resetLocalStorage = () => window.localStorage.removeItem('state')

export const store = getStore()

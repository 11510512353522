import { Record } from 'immutable'

export const User = Record({
  __type: 'User',
  id: null,
  displayName: null,
  email: null,
  token: null,
  role: null,
  phoneNumber: null,
  latLng: null,
})

import React from 'react'
import { Layout } from 'common/layouts/Layout'
import { ArrowBackIcon } from 'common/icons'
import { Button } from 'common/Button'
import styles from './NotDoneYetPage.module.css'

export class NotDoneYetPage extends React.PureComponent {
  render() {
    const { history } = this.props

    return (
      <Layout>
        <div className={styles.NotDoneYetPage}>
          <div>
            <h1>Oops</h1>
            <p>We're still working on this feature!</p>
            <p>
              <Button primary onClick={history.goBack}>
                <ArrowBackIcon inline />
                Back
              </Button>
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

import React from 'react'
import styles from './Grid.module.scss'
import cx from 'classnames'

export class Grid extends React.PureComponent {
  render() {
    const { className, cols, children, style, ...props } = this.props
    const gridStyle = {
      // ...style,
      // gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`,
    }
    return (
      <div className={cx(styles.Grid, className)} style={gridStyle} {...props}>
        {children}
      </div>
    )
  }
}

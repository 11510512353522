import { notes } from './notes'
import { auth } from './auth'
import { sites } from './sites'
import { maps } from './maps'
import { users } from './users'
import { betaInvites } from './beta_invites'
import { photos } from './photos'
import { taskRuns } from './task_runs'
import { detections } from './detections'

export const api = {
  notes,
  auth,
  sites,
  maps,
  users,
  betaInvites,
  taskRuns,
  detections,
  photos,
}

import React, { useState } from 'react'
import styles from './DetectionLogPage.module.scss'
import { LayoutNarrow } from 'common/layouts/LayoutNarrow'
import { Helmet } from 'react-helmet'
import { authSelectors } from 'reducers/auth'
import { useSelector } from 'react-redux'
import { api } from 'api'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import * as dateFormats from 'lib/date-formats'
import { day } from 'lib/day'
import { Button } from 'common/Button'
import { useSessionState } from 'lib/useSessionState'
import { CircleSpinner } from 'common/spinners'

const pluralize = (n, singular, plural) =>
  n === 1
    ? `${n} ${singular}`
    : `${Math.ceil(n).toLocaleString('en-US')} ${plural}`

const relativeIntervalPrecise = (ts1, ts2) => {
  const diffMinutes = day(ts2).diff(ts1, 'minutes', true)
  const minString = diffMinutes.toLocaleString('en-US', {
    maximumFractionDigits: 1,
  })

  switch (true) {
    case diffMinutes < 1:
      return '1 minute'
    case diffMinutes < 5:
      return `${minString} ${minString === '1' ? 'minute' : 'minutes'}`
    case diffMinutes < 10:
      return '10 minutes'
    case diffMinutes < 15:
      return '15 minutes'
    case diffMinutes < 20:
      return '20 minutes'
    case diffMinutes < 30:
      return '30 minutes'
    case diffMinutes < 45:
      return '45 minutes'
    case diffMinutes < 60:
      return 'an hour'
    case diffMinutes < 90:
      return '90 minutes'
    case diffMinutes < 60 * 24:
      return pluralize(day(ts2).diff(ts1, 'hours', true), 'hour', 'hours')
    default:
      return pluralize(day(ts2).diff(ts1, 'days', true), 'day', 'days')
  }
}

const formatTs = (ts) => day(ts).format('MMM D [at] h:mm A')

export const DetectionLogPage = () => {
  const token = useSelector(authSelectors.token)
  const [excludePines, setExcludePines] = useSessionState(
    'detection-log/excludePines',
    true,
  )
  const detectionsQuery = useQuery(
    ['DETECTIONS', excludePines],
    () =>
      api.detections.index(token, {
        excludePines,
      }),
    {
      refetchInterval: 60 * 1000,
    },
  )
  const detections = detectionsQuery.data?.detections

  return (
    <LayoutNarrow>
      <Helmet title="Detections" />
      <div className={styles.DetectionLogPage}>
        <div className={styles.header}>
          <h1>Detections</h1>
          <label>
            <input
              type="checkbox"
              checked={excludePines}
              onChange={() => setExcludePines(!excludePines)}
            />{' '}
            Exclude Yosemite Pines Campgrounds
          </label>
        </div>
        {detectionsQuery.isLoading && (
          <div className={styles.spinner}>
            <CircleSpinner />
          </div>
        )}
        {detectionsQuery.isSuccess && (
          <DetectionLog detections={detections} initialItems={30} />
        )}
      </div>
    </LayoutNarrow>
  )
}

export const DetectionLogEntry = ({ detection, showHeader }) => {
  const trackingState = detection.allClaimedAt ? 'closed' : 'tracking'
  return (
    <div className={styles.DetectionLogEntry}>
      <div>
        <div className={cx(styles.badge, styles[trackingState])}>
          {trackingState}
        </div>
      </div>
      <div className={styles.explainerText}>
        {showHeader && (
          <h3>
            <Link to={`/sites/${detection.siteId}`}>
              {detection.siteEmoji} {detection.siteName}
            </Link>
          </h3>
        )}
        On{' '}
        <span title={detection.detectedAt} data-tip>
          {formatTs(detection.detectedAt)}
        </span>{' '}
        we detected {detection.dates.length} new
        {detection.dates.length === 1 ? ' availability' : ' availabilities'} for
        this site.
        <br />
        {detection.allClaimedAt
          ? `All detected availabilities were claimed within ${relativeIntervalPrecise(
              detection.detectedAt,
              detection.allClaimedAt,
            )}.`
          : `${
              detection.dates.length - Object.keys(detection.claimedAt).length
            } of ${detection.dates.length} dates remain unclaimed.`}
      </div>
      <div className={styles.dates}>
        {detection.dates.map((str) => {
          const d = day.utc(str)
          const claimed = detection.claimedAt[d.format(dateFormats.dbDate)]
          return (
            <div
              key={d}
              className={cx(styles.date, claimed && styles.claimed)}
              data-tip
              data-tippy-delay="[50, 0]"
              title={
                claimed &&
                `claimed within ${relativeIntervalPrecise(
                  detection.detectedAt,
                  claimed,
                )}`
              }
            >
              {d.format(dateFormats.short)}
            </div>
          )
        })}
      </div>
    </div>
  )
}

DetectionLogEntry.defaultProps = {
  showHeader: true,
}

export const DetectionLog = ({ detections, showHeader, initialItems }) => {
  const [maxItems, setMaxItems] = useState(initialItems)
  const incrementMaxItems = () => setMaxItems((count) => count + 10)

  return (
    <div className={styles.HistoryList}>
      {detections.slice(0, maxItems).map((d) => (
        <DetectionLogEntry key={d.id} detection={d} showHeader={showHeader} />
      ))}
      <div className={styles.showMoreContainer}>
        {maxItems < detections.length && (
          <Button outline primary onClick={incrementMaxItems}>
            Show More
          </Button>
        )}
        {maxItems >= detections.length && (
          <span className={styles.showingAll}>
            No {detections.length > 0 ? 'more' : ''} history to show
          </span>
        )}
      </div>
    </div>
  )
}
DetectionLog.defaultProps = {
  initialItems: 10,
}

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(updateLocale)
dayjs.extend(utc)
dayjs.extend(duration)

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: (number, withoutSuffix) =>
      number === 'just now' || withoutSuffix ? number : `${number} ago`,
    s: 'just now',
    m: 'just now',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
})
export const day = dayjs

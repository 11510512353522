import React, { useState } from 'react'
import styles from './NewUserPage.module.scss'
import { LayoutNarrow } from 'common/layouts/LayoutNarrow'
import { Helmet } from 'react-helmet'
import { authSelectors } from 'reducers/auth'
import { useSelector } from 'react-redux'
import { api } from 'api'
import { FormControl } from 'common/FormControl'
import { Button } from 'common/Button'
import { useMutation } from 'react-query'
import { FormError } from 'common/forms'

export const NewUserPage = (props) => {
  const token = useSelector(authSelectors.token)

  const [user, setUser] = useState({
    email: '',
    password: '',
    phoneNumber: '',
    latLng: { lng: '', lat: '' },
  })

  const createUserMutation = useMutation(() => api.users.create(token, user))

  const onSubmit = (e) => {
    e.preventDefault()
    createUserMutation.mutate()
  }

  const onChange = (patch) => setUser((u) => ({ ...u, ...patch }))
  const onChangeLoc = (key, coordStr) => {
    setUser((u) => {
      const latLng = { ...u.latLng, [key]: coordStr }
      return { ...u, latLng }
    })
  }
  console.log({
    error: createUserMutation.error,
    data: createUserMutation.data,
  })

  return (
    <LayoutNarrow>
      <Helmet title="New User" />
      <div className={styles.NewUserPage}>
        <h1>New User</h1>
        <form onSubmit={onSubmit}>
          <FormControl>
            <label>Email</label>
            <input
              type="email"
              value={user.email}
              onChange={(e) => onChange({ email: e.target.value })}
            />
          </FormControl>
          <FormControl>
            <label>Password</label>
            <input
              type="text"
              value={user.password}
              onChange={(e) => onChange({ password: e.target.value })}
            />
          </FormControl>
          <FormControl>
            <label>Phone Number</label>
            <input
              type="tel"
              value={user.phoneNumber}
              placeholder="+15034278466"
              onChange={(e) => onChange({ phoneNumber: e.target.value })}
            />
          </FormControl>
          <FormControl>
            <label>Lat</label>
            <input
              type="text"
              value={String(user.latLng.lat)}
              placeholder="44.05302592063607"
              onChange={(e) => onChangeLoc('lat', e.target.value)}
            />
          </FormControl>
          <FormControl>
            <label>Lng</label>
            <input
              type="text"
              value={String(user.latLng.lng)}
              placeholder="-121.33477362431559"
              onChange={(e) => onChangeLoc('lng', e.target.value)}
            />
          </FormControl>
          <FormError error={createUserMutation.error?.error?.message} />
          <Button
            loading={createUserMutation.isLoading}
            type="submit"
            disabled={createUserMutation.isLoading}
            primary
            filled
          >
            Create User
          </Button>
          {createUserMutation.data && (
            <>
              <h2>New User Created:</h2>
              <pre>{JSON.stringify(createUserMutation.data, null, '  ')}</pre>
            </>
          )}
        </form>
      </div>
    </LayoutNarrow>
  )
}

import { apiRequest } from 'lib/apiRequest'
const { get, post } = apiRequest

// todo - redirect to /login if no token is provided for an endpoint that requires
// authentication
export const sites = {
  index: (token, where) => get(token, `/sites`, where),
  watch: (token, siteId) => post(token, `/sites/${siteId}/watch`),
  unwatch: (token, siteId) => post(token, `/sites/${siteId}/unwatch`),
  show: (token, siteId) => get(token, `/sites/${siteId}`),
  history: (token, siteId) => get(token, `/sites/${siteId}/history`),
  setPaused: (token, siteId, pauseScans) =>
    post(token, `/sites/${siteId}/pauseScans`, { pauseScans }),
}

import { Map } from 'immutable'
import { toastActions } from 'reducers/toast'
import { notesTypes } from 'reducers/notes'
import { mapsTypes } from 'reducers/maps'
import { authTypes } from 'reducers/auth'

const TOAST_LONG = 8000
const TOAST_SHORT = 4000
const TOAST_SHOW_DELAY = 150

const toastTriggers = Map({
  [notesTypes.DISCARD_PENDING_EDITS]: {
    duration: TOAST_LONG,
  },
  [notesTypes.UPDATE_NOTE_FULFILLED]: {
    duration: TOAST_SHORT,
  },
  [mapsTypes.UPDATE_MAP_FULFILLED]: {
    duration: TOAST_SHORT,
    text: 'Map saved',
  },
  [authTypes.LOGOUT_SUCCESS]: {
    duration: TOAST_SHORT,
    text: 'Logged out',
  },
  [notesTypes.DELETE_NOTE_FULFILLED]: {
    duration: TOAST_SHORT,
    text: 'Note deleted', // TODO add an undo action to this!
  },
})

let timer = null
const schedule = (fn, timeout) => {
  clearTimeout(timer)
  console.log('cleared scheduled function')
  timer = setTimeout(fn, timeout)
}

export const toastMiddleware = (store) => (next) => (action) => {
  if (!action || !action.type) {
    return next(action)
  }

  if (toastTriggers.has(action.type)) {
    const { duration, text } = toastTriggers.get(action.type)
    const hide = () => store.dispatch(toastActions.dismissToast())
    const show = () => {
      store.dispatch(toastActions.showToast(action, text))
      schedule(hide, duration)
    }
    schedule(show, TOAST_SHOW_DELAY)
  }

  return next(action)
}

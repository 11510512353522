import React from 'react'
import styles from './Setting.module.scss'

export const Setting = ({ title, action, children }) => (
  <div className={styles.Setting}>
    <h3>{title}</h3>
    <div className={styles.flex}>
      <div className={styles.label}>{children}</div>
      <div className={styles.action}>{action}</div>
    </div>
  </div>
)

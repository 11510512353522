import React from 'react'
import styles from './Modal.module.scss'
import { Dialog } from '@headlessui/react'
import { CloseIcon } from 'common/icons'

// TODO - dismiss modal on back/navigation
export class Modal extends React.PureComponent {
  static defaultProps = {
    withPadding: true,
    centered: true,
    minWidth: '200px',
    width: undefined,
  }

  componentDidMount = () => {
    document.body.classList.add('modalOpen')
  }

  componentWillUnmount = () => {
    document.body.classList.remove('modalOpen')
  }

  render() {
    const { title, children, footer, onRequestClose, description } = this.props

    return (
      <Dialog open onClose={onRequestClose} className={styles.Dialog}>
        <Dialog.Overlay className={styles.backdrop} />
        <div className={styles.Modal}>
          <button className={styles.closeButton} onClick={onRequestClose}>
            <CloseIcon />
          </button>
          <Dialog.Title className={styles.title}>{title}</Dialog.Title>
          {description && (
            <Dialog.Description>{description}</Dialog.Description>
          )}
          {children}
          {footer && <div className={styles.footer}>{footer}</div>}
        </div>
      </Dialog>
    )
  }
}

import React from 'react'
import { Link } from 'react-router-dom'
import { day } from 'lib/day'
import { Table } from 'common/Table'
import styles from './TaskRunsTable.module.scss'
const fmtTs = (ts) => day(ts).format('H:mm:ss')
const fmtDate = (ts) => day(ts).format('YYYY-MM-DD')

export const TaskRunsTable = ({ taskRuns }) => (
  <div className={styles.container}>
    <Table block>
      <thead>
        <tr>
          <th>ID</th>
          <th>Task</th>
          <th></th>
          <th>Date</th>
          <th>Start</th>
          <th>End</th>
        </tr>
      </thead>
      <tbody>
        {taskRuns.map((run) => (
          <tr key={run.id}>
            <td>
              <Link to={`/admin/task_logs/${run.id}`}>{run.id}</Link>
            </td>
            <td>{run.name}</td>
            <td>
              {run.meta?.errorCount > 0 && (
                <>
                  <span
                    className={styles.emoji}
                    data-tip
                    data-tippy-delay="[100,0]"
                    title={`${run.meta.errorCount}/${run.meta.totalScans} scan${
                      run.meta.errorCount === 1 ? '' : 's'
                    } aborted`}
                  >
                    ⚠️
                  </span>{' '}
                  {run.meta.errorCount}
                </>
              )}
            </td>
            <td>{fmtDate(run.startedAt)}</td>
            <td title={run.startedAt}>{fmtTs(run.startedAt)}</td>
            <td title={run.finishedAt}>
              {run.finishedAt ? (
                fmtTs(run.finishedAt)
              ) : (
                <span className={styles.emoji}>🔄</span>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
)

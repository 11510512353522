import React from 'react'
import styles from './WatchCard.module.scss'
import { NotificationsIcon, ColorWandIcon } from 'common/icons'
import { Button } from 'common/Button'
import { useDeleteWatch } from 'queries/watches'
import { modalTypes } from 'reducers/modal'
import { useShowModal } from 'lib/useShowModal'

// would probably make sense to move this text to the backend?
const getWatchDescriptionText = (watch) => {
  if (!watch) return 'error: unknown watch params'
  const { filters } = watch
  const hasFilters = Object.keys(filters).length > 0
  if (!hasFilters) {
    return 'You are watching this site and will get notifications for any new availability in the next 6 months.'
  }

  const prefix = (() => {
    switch (true) {
      case Boolean(filters.isAfterInclusive && filters.isBeforeInclusive):
        return `You are watching this site and will get notifications for any new availability between ${filters.isAfterInclusive} and ${filters.isBeforeInclusive}`
      case Boolean(filters.isAfterInclusive):
        return `You are watching this site and will get notifications for any new availability on or after ${filters.isAfterInclusive}`
      case Boolean(filters.isBeforeInclusive):
        return `You are watching this site and will get notifications for any new availability on or before ${filters.isBeforeInclusive}`
      default:
        return `You are watching this site and will get notifications for any new availability`
    }
  })()

  const minStayStr = filters.minimumStayNights
    ? ` with at least ${filters.minimumStayNights} nights available in a row`
    : ''

  const fullDayStrings = {
    Mo: 'Monday',
    Tu: 'Tuesday',
    We: 'Wednesday',
    Th: 'Thursday',
    Fr: 'Friday',
    Sa: 'Saturday',
    Su: 'Sunday',
  }

  const daysOfWeekStr = filters.daysOfWeek
    ? ' occurring on ' +
      ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
        .filter((d) => filters.daysOfWeek?.[d])
        .map((k) => fullDayStrings[k])
        .join('/')
    : ''

  return prefix + daysOfWeekStr + minStayStr + '.'
}

export const WatchCard = ({ watch, site }) => {
  const deleteWatch = useDeleteWatch()
  const showModal = useShowModal()
  const showEditWatch = () => showModal(modalTypes.EDIT_WATCH, { watch, site })
  return (
    <div className={styles.watchingNotice}>
      <div className={styles.noticeText}>
        <div className={styles.watchingIcon}>
          <NotificationsIcon inline /> Watching{' '}
          {watch.highFrequency ? '(High Frequency)' : ''}
        </div>
        {watch.autoBook && (
          <div className={styles.watchingIcon}>
            <ColorWandIcon inline /> Auto-Book Enabled (Group Size{' '}
            {watch.autoBookParams?.groupSize})
          </div>
        )}
      </div>
      <div className={styles.buttons}>
        <p>{getWatchDescriptionText(watch)}</p>
        <div style={{ textAlign: 'right' }}>
          <Button primary onClick={showEditWatch}>
            Edit Watch
          </Button>{' '}
          <Button
            danger
            onClick={() => deleteWatch.mutate({ watchId: watch.id })}
            loading={deleteWatch.isLoading}
          >
            Remove Watch
          </Button>
        </div>
      </div>
    </div>
  )
}

import { apiRequest } from 'lib/apiRequest'
const { get, post, _delete } = apiRequest

// todo - redirect to /login if no token is provided for an endpoint that requires
// authentication
export const betaInvites = {
  index: (token, where) => get(token, '/admin/beta_invites', where),
  create: (token, { email }) =>
    post(token, '/admin/beta_invites', { invite: { email } }),
  delete: (token, invite) => _delete(token, `/admin/beta_invites/${invite.id}`),
}

import React from 'react'
import styles from './FormControl.module.css'
import cx from 'classnames'

export class FormControl extends React.PureComponent {
  render() {
    const { children, inline, className } = this.props
    return (
      <div
        className={cx(className, styles.FormControl, inline && styles.inline)}
      >
        {children}
      </div>
    )
  }
}

import React from 'react'
import { CircleSpinner } from 'common/spinners'
import { LayoutNarrow } from 'common/layouts/LayoutNarrow'
import styles from './LoadingPage.module.scss'

export class LoadingPage extends React.PureComponent {
  render() {
    return (
      <LayoutNarrow>
        <div className={styles.LoadingPage}>
          <div className={styles.content}>
            <p className={styles.spinner}>
              <CircleSpinner />
            </p>
            <p data-light>Loading</p>
          </div>
        </div>
      </LayoutNarrow>
    )
  }
}

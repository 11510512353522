import { useAuthToken } from 'lib/useAuthToken'
import { apiRequest } from 'lib/apiRequest'

export const useAuthenticatedRequest = () => {
  const token = useAuthToken()

  const authenticatedRequestAPI = Object.fromEntries(
    Object.entries(apiRequest).map(([k, fn]) => [k, fn.bind(undefined, token)]),
  )

  return authenticatedRequestAPI
}

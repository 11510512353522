import React from 'react'
import { connect } from 'react-redux'
import { modalSelectors, modalActions, modalTypes } from 'reducers/modal'
import { ConfirmationModal } from './ConfirmationModal'
import { RenameMapModal } from './RenameMapModal'
import { ShareMapModal } from './ShareMapModal'
import { EditWatchModal } from './EditWatchModal'

export class ModalOverlay extends React.PureComponent {
  render() {
    const { activeModal, dismissModal } = this.props
    if (!activeModal) {
      return null
    }

    const modalProps = {
      ...activeModal.props,
      onRequestClose: dismissModal,
    }

    switch (activeModal.type) {
      case modalTypes.CONFIRMATION:
        return <ConfirmationModal {...modalProps} />
      case modalTypes.RENAME_MAP:
        return <RenameMapModal {...modalProps} />
      case modalTypes.SHARE_MAP:
        return <ShareMapModal {...modalProps} />
      case modalTypes.EDIT_WATCH:
        return <EditWatchModal {...modalProps} />
      default:
        console.warn('unknown modal type ', activeModal.type)
        return null
    }
  }
}

const mapStateToProps = (state) => ({
  activeModal: modalSelectors.activeModal(state),
})

const mapDispatchToProps = {
  dismissModal: modalActions.dismissModal,
}

export const ModalOverlayContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalOverlay)

import { Record, Map } from 'immutable'

export const Note = Record({
  __type: 'Note',
  id: null,
  mapId: null,
  createdAt: null,
  updatedAt: null,
  lngLat: { lng: 0, lat: 0 },
  title: '',
  tags: '',
  content: '',
  color: '#E7453C', // todo abstract this into a lookup table for better theming
  pendingEdits: Map(),
  discardedEdits: Map(),
})

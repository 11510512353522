import { SMap } from 'datatypes/SMap'

export const urls = {
  newMap: () => `/maps/new`,
  viewMap: (map) => `/maps/${SMap.slug(map)}`,
  viewList: (map) => `${urls.viewMap(map)}/list`,
  onboarding: () => `/welcome`,
  viewNoteOnMap: (map, id) => `${urls.viewMap(map)}/places/${id}`,
  editNoteOnMap: (map, id) => `${urls.viewNoteOnMap(map, id)}/edit`,
  viewNoteInList: (map, id) => `${urls.viewList(map)}/${id}`,
  editNoteInList: (map, id) => `${urls.viewList(map)}/${id}/edit`,
}

export const fullURL = (path) =>
  process.env.NODE_ENV === 'production'
    ? 'https://scoutmaps.io' + path
    : window.location.origin + path

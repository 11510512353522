import React from 'react'
import styles from './Table.module.scss'
import cx from 'classnames'

export class Table extends React.PureComponent {
  render() {
    const { children, className, block, ...props } = this.props
    return (
      <table
        className={cx(styles.Table, block && styles.block, className)}
        {...props}
      >
        {children}
      </table>
    )
  }
}

import { Record } from 'immutable'
import { slugify } from 'lib/slugify'

// we call it SMap (short for ScoutMap) to avoid naming collisions with
// Immutable.Map or the map builtin
export const SMap = Record({
  __type: 'SMap',
  id: null,
  userId: null,
  userName: null,
  title: null,
  bounds: null,
  public: false,
  basemapId: 'outdoors',
})

SMap.slug = (m) => `${m.id}-${slugify(m.title)}`

import React from 'react'
import { useSessionState } from 'lib/useSessionState'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Layout } from 'common/layouts/Layout'
import styles from './SiteListPage.module.scss'
import { mapsActions, mapsSelectors } from 'reducers/maps'
import { LoadableQueryPage } from 'common/LoadableQueryPage'
import { FormControl } from 'common/FormControl'
import { Button } from 'common/Button'
import { SiteSummaryCard } from 'common/SiteSummaryCard'
import { api } from 'api'
import { useQuery } from 'react-query'
import { authSelectors } from 'reducers/auth'
import { useSelector } from 'react-redux'
import { Grid } from 'common/Grid'
import * as dateFormats from 'lib/date-formats'
import { day } from 'lib/day'
import { QuickScore } from 'quick-score'
import {
  PopoverMenu,
  PopoverMenuItem,
  PopoverMenuDivider,
} from 'common/PopoverMenu'
import {
  CalendarOutlineIcon,
  CloseIcon,
  NotificationsIcon,
  NotificationsOffOutlineIcon,
  SunnyOutlineIcon,
  MoonOutlineIcon,
  PinOutlineIcon,
  ArrowDownIcon,
  KeypadOutlineIcon,
} from 'common/icons'
import _ from 'lodash'

const qs = _.memoize((items) => new QuickScore(items, ['name', 'emoji']))

const FIVE_MINUTES = 5 * 60 * 1000

export const LoadableSiteListPage = (props) => {
  const token = useSelector(authSelectors.token)
  const sitesQuery = useQuery('SITES', () => api.sites.index(token), {
    refetchInterval: FIVE_MINUTES,
  })
  const sites = sitesQuery.data?.sites
  return (
    <LoadableQueryPage query={sitesQuery} Page={SiteListPage} sites={sites} />
  )
}

// TODO - store filters in the query string.
// useful reference: https://reactrouter.com/web/example/query-parameters
export const SiteListPage = ({ sites }) => {
  const [watchingFilter, setWatchingFilter] = useSessionState(
    'filters/watching',
    null,
  )
  const [typeFilter, setTypeFilter] = useSessionState('filters/type', null)
  const [availabilityFilter, setAvailabilityFilter] = useSessionState(
    'filters/availability',
    null,
  )
  const [keywordFilter, setKeywordFilter] = useSessionState(
    'filters/keyword',
    '',
  )

  const filterByWatching = (sites) => {
    if (watchingFilter === null) {
      return sites
    }
    if (watchingFilter === true) {
      return sites.filter((s) => s.watching)
    }
    if (watchingFilter === false) {
      return sites.filter((s) => !s.watching)
    }
    throw Error('unknown value for watching filter')
  }

  const filterByType = (sites) => {
    if (typeFilter === null) {
      return sites
    }
    return sites.filter((s) => s.siteType === typeFilter)
  }

  const filterByAvailability = (sites) => {
    if (availabilityFilter === null) {
      return sites
    }
    if (availabilityFilter === 'anytime') {
      return sites.filter((s) => s.nextAvailable)
    }
    if (availabilityFilter === 'in the next 7 days') {
      const start = day().utc().format(dateFormats.dbDate)
      const end = day().utc().add(7, 'days').format(dateFormats.dbDate)
      return sites.filter(
        (s) => start <= s.nextAvailable && s.nextAvailable <= end,
      )
    }
    if (availabilityFilter === 'in the next 30 days') {
      const start = day().utc().format(dateFormats.dbDate)
      const end = day().utc().add(30, 'days').format(dateFormats.dbDate)
      return sites.filter(
        (s) => start <= s.nextAvailable && s.nextAvailable <= end,
      )
    }
    throw Error('unknown value for availability filter')
  }

  const filterByKeyword = (sites) => {
    const kw = keywordFilter.trim()

    if (keywordFilter.length === 0) {
      return sites
    }

    return qs(sites)
      .search(kw)
      .map((result) => result.item)
  }

  // todo express this as a pipe?
  const filteredSites = filterByKeyword(
    filterByAvailability(filterByType(filterByWatching(sites))),
  )

  return (
    <Layout>
      <Helmet title="Your Sites" />
      <div className={styles.SiteListPage}>
        <h1>Your Sites</h1>
        <div className={styles.Filters}>
          <KeywordFilter
            keywordFilter={keywordFilter}
            setKeywordFilter={setKeywordFilter}
          />
          <div>
            <AvailabilityFilter
              availabilityFilter={availabilityFilter}
              setAvailabilityFilter={setAvailabilityFilter}
            />
            <WatchingFilter
              watchingFilter={watchingFilter}
              setWatchingFilter={setWatchingFilter}
            />
            <TypeFilter typeFilter={typeFilter} setTypeFilter={setTypeFilter} />
          </div>
        </div>
        <Grid cols={3} style={{ gridRowGap: '40px', gridColumnGap: '40px' }}>
          {filteredSites.map((s) => (
            <SiteSummaryCard site={s} key={s.id} />
          ))}
        </Grid>
        {filteredSites.length === 0 && <div>No sites match your filters</div>}
      </div>
    </Layout>
  )
}

const AvailabilityFilter = ({ availabilityFilter, setAvailabilityFilter }) => {
  const isActive = availabilityFilter !== null
  return (
    <PopoverMenu
      title="Show sites available"
      trigger={
        <Button
          outline={!isActive}
          filled={isActive}
          primary={isActive}
          className={styles.filterButton}
        >
          {availabilityFilter === null
            ? 'Availability'
            : `Available ${availabilityFilter}`}{' '}
          <ArrowDownIcon inline />
        </Button>
      }
    >
      <PopoverMenuItem
        closePopoverOnClick
        icon={CalendarOutlineIcon}
        onClick={() => setAvailabilityFilter('anytime')}
      >
        Anytime
      </PopoverMenuItem>
      <PopoverMenuItem
        closePopoverOnClick
        icon={CalendarOutlineIcon}
        onClick={() => setAvailabilityFilter('in the next 7 days')}
      >
        Next 7 days
      </PopoverMenuItem>
      <PopoverMenuItem
        closePopoverOnClick
        icon={CalendarOutlineIcon}
        onClick={() => setAvailabilityFilter('in the next 30 days')}
      >
        Next 30 days
      </PopoverMenuItem>
      {isActive && <PopoverMenuDivider />}
      {isActive && (
        <PopoverMenuItem
          closePopoverOnClick
          data-accent
          icon={CloseIcon}
          onClick={() => setAvailabilityFilter(null)}
        >
          Clear filter
        </PopoverMenuItem>
      )}
    </PopoverMenu>
  )
}

const KeywordFilter = ({ keywordFilter, setKeywordFilter }) => {
  return (
    <FormControl inline className={styles.keywordFilter}>
      <input
        value={keywordFilter}
        onChange={(e) => {
          setKeywordFilter(e.target.value)
        }}
        type="text"
        placeholder="Search sites"
        className={styles.keywordInput}
      />
      {keywordFilter.trim().length > 0 && (
        <button
          className={styles.clearKeyword}
          onClick={() => setKeywordFilter('')}
        >
          <CloseIcon />
        </button>
      )}
    </FormControl>
  )
}

const WatchingFilter = ({ watchingFilter, setWatchingFilter }) => {
  const isActive = watchingFilter !== null
  return (
    <PopoverMenu
      title="Show"
      trigger={
        <Button
          outline={!isActive}
          filled={isActive}
          primary={isActive}
          className={styles.filterButton}
        >
          {watchingFilter === null
            ? 'Status'
            : watchingFilter
            ? 'Watching'
            : 'Not watching'}{' '}
          <ArrowDownIcon inline />
        </Button>
      }
    >
      <PopoverMenuItem
        closePopoverOnClick
        icon={NotificationsIcon}
        onClick={() => setWatchingFilter(true)}
      >
        Watching
      </PopoverMenuItem>
      <PopoverMenuItem
        closePopoverOnClick
        icon={NotificationsOffOutlineIcon}
        onClick={() => setWatchingFilter(false)}
      >
        Not watching
      </PopoverMenuItem>
      {isActive && <PopoverMenuDivider />}
      {isActive && (
        <PopoverMenuItem
          closePopoverOnClick
          data-accent
          icon={CloseIcon}
          onClick={() => setWatchingFilter(null)}
        >
          Clear filter
        </PopoverMenuItem>
      )}
    </PopoverMenu>
  )
}

const typeLabels = {
  'permit-day-use': 'Day Use Permit',
  'permit-overnight': 'Overnight Permit',
  campsite: 'Campsite',
  campground: 'Campground',
}

const TypeFilter = ({ typeFilter, setTypeFilter }) => {
  const isActive = typeFilter !== null
  return (
    <PopoverMenu
      title="Types of site"
      trigger={
        <Button
          outline={!isActive}
          filled={isActive}
          primary={isActive}
          className={styles.filterButton}
        >
          {typeFilter === null ? 'Type' : typeLabels[typeFilter]}{' '}
          <ArrowDownIcon inline />
        </Button>
      }
    >
      <PopoverMenuItem
        closePopoverOnClick
        icon={SunnyOutlineIcon}
        onClick={() => setTypeFilter('permit-day-use')}
      >
        Day Use Permit
      </PopoverMenuItem>
      <PopoverMenuItem
        closePopoverOnClick
        icon={MoonOutlineIcon}
        onClick={() => setTypeFilter('permit-overnight')}
      >
        Overnight Permit
      </PopoverMenuItem>
      <PopoverMenuItem
        closePopoverOnClick
        icon={PinOutlineIcon}
        onClick={() => setTypeFilter('campsite')}
      >
        Campsite
      </PopoverMenuItem>
      <PopoverMenuItem
        closePopoverOnClick
        icon={KeypadOutlineIcon}
        onClick={() => setTypeFilter('campground')}
      >
        Campground
      </PopoverMenuItem>
      {isActive && <PopoverMenuDivider />}
      {isActive && (
        <PopoverMenuItem
          closePopoverOnClick
          data-accent
          icon={CloseIcon}
          onClick={() => setTypeFilter(null)}
        >
          Clear filter
        </PopoverMenuItem>
      )}
    </PopoverMenu>
  )
}

const mapStateToProps = (state) => ({
  maps: mapsSelectors.currentUserMaps(state),
})

const mapDispatchToProps = {
  fetchAllSites: mapsActions.fetchAllSites,
}

export const SiteListPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SiteListPage)

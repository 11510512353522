import { fetchJSON } from 'lib/fetchJSON'

const API_ENDPOINT = '/api'

// todo - use URLParams here! - see OSMSearch for an example
const buildQueryString = (params) =>
  Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')

const urlWithParams = (path, params) => {
  if (!params) {
    return path
  }
  const query = buildQueryString(params)
  return query ? path + '?' + query : path
}

// FIXME - we need to auto-logout if there's a 401 anywhere
const apiHeaders = ({ token }) => (token ? { Authorization: token } : {})

const get = (token, path, params) =>
  fetchJSON(API_ENDPOINT + urlWithParams(path, params), null, {
    headers: apiHeaders({ token }),
  })

const post = (token, path, body) =>
  fetchJSON(API_ENDPOINT + path, body, {
    headers: apiHeaders({ token }),
    method: 'POST',
  })

const postMultipart = (token, path, body) => {
  const formData = new window.FormData()
  Object.entries(body).forEach(([k, v]) => formData.append(k, v))
  const fetchOpts = {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
      Authorization: token,
    },
  }
  return window
    .fetch(API_ENDPOINT + path, fetchOpts)
    .then((response) => response.json()) // todo - error handling and JSON response!
}

const patch = (token, path, body) =>
  fetchJSON(API_ENDPOINT + path, body, {
    headers: apiHeaders({ token }),
    method: 'PATCH',
  })

const _delete = (token, path, body) =>
  fetchJSON(API_ENDPOINT + path, body, {
    headers: apiHeaders({ token }),
    method: 'DELETE',
  })

export const apiRequest = {
  get,
  post,
  postMultipart,
  patch,
  _delete,
}

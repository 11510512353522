import { apiRequest } from 'lib/apiRequest'
const { post } = apiRequest

export const auth = {
  login: ({ email, password }) =>
    post(null, '/auth/login', { user: { email, password } }),
  signup: ({ email, password }) =>
    post(null, '/auth/signup', { user: { email, password } }),
  logout: (token) => post(token, '/auth/logout'),
  changePassword: (token, { oldPassword, newPassword }) =>
    post(token, '/auth/change_password', {
      password: { oldPassword, newPassword },
    }),
}

import React from 'react'
import { useQuery } from 'react-query'
import styles from '../Navbar.module.scss'
import {
  // CheckmarkIcon,
  LeafOutlineIcon,
  NotificationsIcon,
} from 'common/icons'
import cx from 'classnames'
import {
  PopoverMenu,
  PopoverMenuItem,
  // PopoverMenuDivider,
} from 'common/PopoverMenu'
import { api } from 'api'
import { authSelectors } from 'reducers/auth'
import { useSelector } from 'react-redux'

export const Notifications = () => {
  const user = useSelector(authSelectors.currentUser)
  const token = useSelector(authSelectors.token)
  const notificationsQuery = useQuery(
    'NOTIFICATIONS',
    () => api.users.notifications(token, user.id),
    {
      refetchInterval: 1000 * 60,
    },
  )
  const hasNotifications = notificationsQuery.data?.notifications?.length > 0
  // TODO - full-page notifications on mobile
  // TODO - call notificationsQuery.refetch() when the popover opens
  // TODO - replace react-overlays with something that doesn't constantly
  //        break everything
  return (
    <PopoverMenu
      title="Notifications"
      style={{
        width: '600px',
        maxWidth: '95vw',
      }}
      trigger={<MapButton />}
    >
      {hasNotifications &&
        notificationsQuery.data.notifications.map((n) => (
          // todo support icon = n.meta?.emoji
          <PopoverMenuItem
            key={n.id}
            to={`/sites/${n.meta?.siteId}`}
            icon={n.meta?.emoji || LeafOutlineIcon}
          >
            <span title={n.meta?.dates?.join(', ')}>{n.message}</span>
          </PopoverMenuItem>
        ))}
      {!hasNotifications && (
        <PopoverMenuItem icon={LeafOutlineIcon} style={{ opacity: 0.5 }}>
          No notifications
        </PopoverMenuItem>
      )}
      {/*<PopoverMenuDivider />
      <PopoverMenuItem data-accent icon={CheckmarkIcon}>
        Mark all as read
      </PopoverMenuItem>*/}
    </PopoverMenu>
  )
}

class MapButton extends React.PureComponent {
  render() {
    const { active, ...props } = this.props

    const cn = cx(styles.NavbarButton, active && styles.NavbarButtonActive)

    return (
      <button className={cn} {...props}>
        <NotificationsIcon />
      </button>
    )
  }
}

import React from 'react'
import { RouterContainer } from './Router'
import { Provider } from 'react-redux'
import { QueryClientProvider } from 'react-query'
import tippy from 'tippy.js'
import { store } from './store'
import 'lib/styles/base.scss'
import { queryClient } from 'app/queryClient'

export class App extends React.PureComponent {
  render() {
    return (
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <RouterContainer />
        </QueryClientProvider>
      </Provider>
    )
  }
}

// This miiiiiight cause memory issues because AFAIK tippy doesn't
// destroy internal tooltip instances created via event delegation until
// the parent (in this case, document.body) is destroyed.
//
// That said, if we're hitting memory constraints because of too many
// tooltip-having divs being created & destroyed, we've probably got bigger
// issues ¯\_(ツ)_/¯
const tippyDefaults = {
  target: '[data-tip]',
  dynamicTitle: true,
  animation: 'fade',
  distance: 5,
  arrow: true,
  arrowType: 'round',
  arrowTransform: 'scale(0.7, 0.8)',
  delay: [800, 0], // [show, hide] delay
  duration: [400, 200], // [show, hide] transition duration
}

tippy(document.body, tippyDefaults)

tippy(document.body, {
  ...tippyDefaults,
  target: '[data-tip-slow]',
  delay: [1800, 0],
})

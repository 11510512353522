import React from 'react'
import { day } from 'lib/day'
import styles from './AvailabilityCalendar.module.scss'
import cx from 'classnames'
import * as dateFormats from 'lib/date-formats'

const getMonthRange = (startDate, endDate) => {
  if (endDate.isBefore(startDate)) {
    throw Error('endDate cannot be before startDate')
  }
  const range = []
  let cursor = startDate
  while (cursor.isBefore(endDate)) {
    range.push(cursor.startOf('month'))
    cursor = cursor.add(1, 'month')
  }
  range.push(cursor.startOf('month'))
  return range
}

export const AvailabilityCalendar = ({
  availability,
  getAvailabilityGridUrl,
}) => {
  const sortedDays = Object.keys(availability).sort()
  const firstDay = day(sortedDays[0])
  const lastDay = day(sortedDays[sortedDays.length - 1])
  const monthsToShow = getMonthRange(firstDay, lastDay)

  return (
    <div className={styles.AvailabilityCalendar}>
      {monthsToShow.map((m) => (
        <div key={m.toISOString()}>
          <div className={styles.monthName}>{m.format('MMMM YYYY')}</div>
          <Month
            month={m}
            sortedDays={sortedDays}
            availability={availability}
            getAvailabilityGridUrl={getAvailabilityGridUrl}
          />
        </div>
      ))}
    </div>
  )
}

const getMonthDays = (d) => {
  const days = []
  let cursor = d.startOf('month')
  let end = d.endOf('month')
  while (cursor.isBefore(end)) {
    days.push(cursor)
    cursor = cursor.add(1, 'day')
  }
  return days
}

const Month = ({ month, sortedDays, availability, getAvailabilityGridUrl }) => {
  const [firstDay, ...monthDays] = getMonthDays(month)
  return (
    <div className={styles.Month}>
      <div className={styles.MonthLabels}>
        <div className={styles.label}>Su</div>
        <div className={styles.label}>Mo</div>
        <div className={styles.label}>Tu</div>
        <div className={styles.label}>We</div>
        <div className={styles.label}>Th</div>
        <div className={styles.label}>Fr</div>
        <div className={styles.label}>Sa</div>
      </div>
      <div className={styles.MonthDays}>
        <Day
          d={firstDay}
          availability={availability}
          style={{ gridColumn: firstDay.day() + 1 }}
          getAvailabilityGridUrl={getAvailabilityGridUrl}
        />
        {monthDays.map((d) => (
          <Day
            d={d}
            availability={availability}
            getAvailabilityGridUrl={getAvailabilityGridUrl}
            key={d.toISOString()}
          />
        ))}
      </div>
    </div>
  )
}

// TODO - if a day is available, make it a link directly to the
// reservation page for a campsite/permit. For example, if
// April 18 was available for https://www.recreation.gov/camping/campgrounds/234149
//
// we'd want to link to https://www.recreation.gov/camping/campsites/82697#site-availability
const Day = ({ d, availability, getAvailabilityGridUrl, ...props }) => {
  const dateString = d.format(dateFormats.dbDate)
  const a = availability[dateString]
  const isAvailable = a?.remaining > 0
  const isLottery = a?.isLottery
  const title = (() => {
    switch (true) {
      case isLottery:
        return 'Lottery Pending'
      case isAvailable:
        return `${a.remaining}/${a.total} remaining`
      default:
        return null
    }
  })()

  const Element = isAvailable ? 'a' : 'div'
  const linkProps = isAvailable
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: getAvailabilityGridUrl(dateString),
      }
    : {}

  return (
    <Element
      {...props}
      {...linkProps}
      data-tip
      title={title}
      className={cx(
        styles.Day,
        isAvailable && styles.isAvailable,
        isLottery && styles.isLottery,
      )}
    >
      <div>{d.format('D')}</div>
      {isAvailable && <div className={styles.statusLetter}>A</div>}
      {isLottery && <div className={styles.statusLetter}>L</div>}
    </Element>
  )
}

import React, { useState } from 'react'
import styles from './TaskRunDetailPage.module.scss'
import { Layout } from 'common/layouts/Layout'
import { Helmet } from 'react-helmet'
import { authSelectors } from 'reducers/auth'
import { useSelector } from 'react-redux'
import { api } from 'api'
import { useQuery } from 'react-query'
import { LoadableQueryPage } from 'common/LoadableQueryPage'
import Ansi from 'ansi-to-react'
import { TaskRunsTable } from 'common/TaskRunsTable'

export const TaskRunDetailPage = ({
  match: {
    params: { runId },
  },
}) => {
  const token = useSelector(authSelectors.token)
  const [refetchInterval, setRefetchInterval] = useState(500)
  const taskRunQuery = useQuery(
    ['TASK_RUN', runId],
    () => api.taskRuns.show(runId, token),
    {
      onSuccess: (data) => {
        if (refetchInterval && taskRunQuery.data?.taskRun?.finishedAt) {
          // disable refetch when task run is finished
          console.log('task completed, disabling refetch')
          setRefetchInterval(undefined)
        }
      },
      refetchInterval, // re-fetch every 500ms until finishedAt is populated
    },
  )
  const taskRun = taskRunQuery.data?.taskRun
  return (
    <LoadableQueryPage
      query={taskRunQuery}
      Page={TaskRunLoaded}
      taskRun={taskRun}
    />
  )
}

// todo - style the classes: https://github.com/nteract/ansi-to-react
const TaskRunLoaded = ({ taskRun }) => {
  return (
    <Layout>
      <Helmet title={`${taskRun.name} #${taskRun.id}`} />
      <div className={styles.TaskRunDetailPage}>
        <div style={{ padding: '16px' }}>
          <h1>{taskRun.name}</h1>
          <TaskRunsTable taskRuns={[taskRun]} />
        </div>
        <pre style={{ padding: '16px' }}>
          <Ansi useClasses>{taskRun.logContent}</Ansi>
        </pre>
      </div>
    </Layout>
  )
}

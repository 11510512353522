import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { authSelectors } from 'reducers/auth'
import { useSelector } from 'react-redux'
import { LoadableQueryPage } from 'common/LoadableQueryPage'
import { api } from 'api'
import { LayoutNarrow } from 'common/layouts/LayoutNarrow'
import {
  OpenOutlineIcon,
  CarIcon,
  PinIcon,
  RefreshIcon,
  CalendarOutlineIcon,
  ArrowDownIcon,
  PauseOutlineIcon,
  PartlySunnyOutlineIcon,
  NavigateOutlineIcon,
  GlobeOutlineIcon,
  PlayOutlineIcon,
} from 'common/icons'
import { Button } from 'common/Button'
import { day } from 'lib/day'
import { AvailabilityCalendar } from 'common/AvailabilityCalendar'
import styles from './SiteDetailPage.module.scss'
import { useMutation } from 'react-query'
import { queryClient } from 'app/queryClient'
import { WatchCard } from 'common/WatchCard'
import { DetectionLog } from 'features/DetectionLogPage'
import { Helmet } from 'react-helmet'
import { getAvailabilityGridUrl } from 'lib/rec-url-helpers'
import { modalTypes } from 'reducers/modal'
import { useShowModal } from 'lib/useShowModal'
import {
  PopoverMenu,
  PopoverMenuItem,
  PopoverMenuDivider,
} from 'common/PopoverMenu'
import { AdminOnly } from 'common/AdminOnly'
import cx from 'classnames'

const timeAgo = (ts) => day(ts).fromNow()
// TODO share scan-utils.js
const getAvailableDays = (availability) => {
  return Object.keys(availability).filter((k) => availability[k].remaining > 0)
}

export const SiteDetailPage = ({
  match: {
    params: { siteId },
  },
}) => {
  const token = useSelector(authSelectors.token)
  // TODO - disable retries for errors like 404, etc, using the retry function
  // config described on https://react-query.tanstack.com/guides/query-retries
  //
  // TODO - the backend can't return drive time data for individual sites
  // because we don't have caching at that level yet; it would end up hitting
  // the goog API a lot more than I'd like. I'd like to show drive times on
  // this page, though!
  const siteQuery = useQuery(['SITES', siteId], () =>
    api.sites.show(token, siteId),
  )

  return (
    <LoadableQueryPage
      Page={LoadedPage}
      query={siteQuery}
      site={siteQuery?.data?.site}
    />
  )
}

const LoadedPage = ({ site }) => {
  const [showDebugData, setShowDebugData] = useState(false)
  const lastScan = site.scans[0]
  // todo wrap this in a custom hook
  const token = useSelector(authSelectors.token)
  const showModal = useShowModal()
  const toggleWatching = useMutation(
    () =>
      site.watching
        ? api.sites.unwatch(token, site.id)
        : api.sites.watch(token, site.id).then(({ watch }) => {
            showModal(modalTypes.EDIT_WATCH, { watch, site })
          }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('SITES')
      },
    },
  )

  return (
    <LayoutNarrow>
      <Helmet title={site.name} />
      <div className={styles.header}>
        <SiteActions site={site} />
        <h1 className={styles.title}>
          {site.pauseScans && '[PAUSED]'} {site.name} {site.emoji}
        </h1>
        <SiteMeta site={site} />
      </div>
      {!site.watching && (
        <div className={styles.addWatch}>
          <p>You are not currently watching this site</p>
          <Button
            primary
            outline
            onClick={toggleWatching.mutate}
            loading={toggleWatching.isLoading}
          >
            Add Watch
          </Button>
        </div>
      )}
      {site.watches.map((watch) => (
        <WatchCard key={watch.id} watch={watch} site={site} />
      ))}
      <div className={styles.availabilitySection}>
        {lastScan?.availability && (
          <AvailabilityCalendar
            getAvailabilityGridUrl={(startDate) =>
              getAvailabilityGridUrl(site, startDate)
            }
            availability={lastScan?.availability}
          />
        )}
      </div>
      <div className={styles.historySection}>
        <h2>History</h2>
        <History site={site} />
      </div>
      <div style={{ marginTop: '64px' }}>
        <Button
          accent
          filled={showDebugData}
          onClick={() => setShowDebugData(!showDebugData)}
        >
          {showDebugData ? 'Hide' : 'Show'} Debug Data
        </Button>
        {showDebugData && <pre>{JSON.stringify(site, undefined, '  ')}</pre>}
      </div>
    </LayoutNarrow>
  )
}

const SiteMeta = ({ site }) => {
  const lastScan = site.scans[0]
  const datesAvailabile = lastScan
    ? getAvailableDays(site.scans[0].availability)
    : []
  const allDays = Object.keys(site.scans[0]?.availability || {}).length
  return (
    <div className={styles.SiteMeta}>
      <div className={styles.detail}>
        <PinIcon inline /> {Math.round(site.distance)} mi away
      </div>
      {site.driveTime?.duration?.text && (
        <div className={styles.detail}>
          <CarIcon inline /> {site.driveTime?.duration?.text}
        </div>
      )}
      <div className={styles.detail}>
        <RefreshIcon inline /> Scanned{' '}
        {lastScan ? timeAgo(lastScan?.finishedAt) : 'never'}
      </div>
      <div className={cx(styles.detail, styles.mobileHidden)}>
        <CalendarOutlineIcon inline /> {datesAvailabile.length}/{allDays} days
        available
      </div>
    </div>
  )
}

const SiteActions = ({ site }) => {
  return (
    <div className={styles.SiteActions}>
      <Button
        href={site.description}
        target="_blank"
        rel="noopener noreferrer"
        primary
      >
        <OpenOutlineIcon inline /> Permit Page
      </Button>
      <MoreActions site={site} />
    </div>
  )
}

const MoreActions = ({ site }) => {
  const token = useSelector(authSelectors.token)
  const togglePaused = useMutation(
    () => api.sites.setPaused(token, site.id, !site.pauseScans),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('SITES')
      },
    },
  )
  return (
    <PopoverMenu
      title="More Actions"
      trigger={
        <Button primary>
          More <ArrowDownIcon inline />
        </Button>
      }
    >
      <PopoverMenuItem
        icon={PartlySunnyOutlineIcon}
        href={`https://forecast.weather.gov/MapClick.php?lat=${site.latLng.lat}&lon=${site.latLng.lng}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        NOAA Weather Forecast
      </PopoverMenuItem>
      <PopoverMenuItem
        icon={NavigateOutlineIcon}
        href={`https://www.google.com/maps/search/?api=1&query=${site.latLng.lat}%2C${site.latLng.lng}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Google Maps
      </PopoverMenuItem>
      <PopoverMenuItem
        icon={GlobeOutlineIcon}
        href={`https://apps.sentinel-hub.com/eo-browser/?zoom=9&lat=${site.latLng.lat}&lng=${site.latLng.lng}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Sentinel-2 Satellite Feed
      </PopoverMenuItem>
      <AdminOnly>
        <PopoverMenuDivider />
        <PopoverMenuItem
          data-admin
          icon={site.pauseScans ? PlayOutlineIcon : PauseOutlineIcon}
          onClick={() => togglePaused.mutate()}
        >
          {site.pauseScans ? 'Resume Scans' : 'Pause Scans'}
        </PopoverMenuItem>
      </AdminOnly>
    </PopoverMenu>
  )
}

const History = ({ site }) => {
  const token = useSelector(authSelectors.token)
  const historyQuery = useQuery(['SITES', site.id, 'HISTORY'], () =>
    api.sites.history(token, site.id),
  )

  if (historyQuery.isLoading) {
    return null
  }
  if (historyQuery.isError) {
    return 'Error loading history'
  }

  return (
    <DetectionLog
      detections={historyQuery.data.detections}
      initialItems={5}
      showHeader={false}
    />
  )
}

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { modalActions } from 'reducers/modal'

export const useShowModal = () => {
  const dispatch = useDispatch()

  return useCallback((...args) => dispatch(modalActions.showModal(...args)), [
    dispatch,
  ])
}

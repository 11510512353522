import { useState } from 'react'

export const useSessionState = (key, initialState) => {
  const keyExistsInSession = !(sessionStorage.getItem(key) === null)
  const [state, setLocalState] = useState(() => {
    if (keyExistsInSession) {
      try {
        return JSON.parse(sessionStorage.getItem(key))[key]
      } catch (e) {
        console.error(`error deserializing value for sessionStorage ${key}`, e)
      }
      return initialState
    }
    return initialState
  })

  const setState = (update) => {
    const value = update instanceof Function ? update(state) : update
    setLocalState(value)
    try {
      // since there is is no sessionStorage.has(key) => bool API,
      // use this {[key]: value} "envelope" so that we can represent the
      // "null" value without resetting to the initial state
      sessionStorage.setItem(key, JSON.stringify({ [key]: value }))
    } catch (e) {
      console.error('Error persisting value to session storage')
    }
  }

  return [state, setState]
}

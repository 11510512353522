import React, { useState } from 'react'
import styles from './EditWatchModal.module.scss'
import { ConfirmationModal } from '../ConfirmationModal'
import { Setting, SettingsSection } from 'common/settings'
import { Map } from 'immutable'
import { useUpdateWatch } from 'queries/watches'
import { AdminOnly } from 'common/AdminOnly'
import cx from 'classnames'

const removeNulls = (map) => map.filter((v) => v !== null)

const siteSupportsAutobook = (site) =>
  site.scanType === 'permitinyo' || site.scanType === 'permit'

export const EditWatchModal = ({ watch, site, onRequestClose }) => {
  const [filters, setFilters] = useState(Map(watch.filters))
  const [highFrequency, setHighFrequency] = useState(watch.highFrequency)
  const supportsAutobook = siteSupportsAutobook(site)
  const [autoBook, setAutoBook] = useState(supportsAutobook && watch.autoBook)
  const [groupSize, setGroupSize] = useState(
    watch.autoBookParams.groupSize || 2,
  )
  const updateWatch = useUpdateWatch()
  const persistChanges = () =>
    updateWatch.mutateAsync({
      watchId: watch.id,
      updates: {
        filters: removeNulls(filters).toJS(),
        highFrequency,
        autoBook,
        autoBookParams: { groupSize },
      },
    })

  const onChangeDate = (key) => (e) => {
    // need to grab a copy of this value because of event pooling
    const value = e.target.value
    setFilters((filters) => filters.set(key, value))
  }

  const onClearDate = (key) => () => {
    setFilters((filters) => filters.set(key, null))
  }

  const onChangeDaysOfWeek = (daysOfWeek) => {
    setFilters((filters) => filters.set('daysOfWeek', daysOfWeek))
  }

  const onClearDaysOfWeek = () => {
    setFilters((filters) => filters.set('daysOfWeek', null))
  }

  const onChangeMinStay = (e) => {
    const value = parseInt(e.target.value, 10)
    setFilters((filters) => filters.set('minimumStayNights', value || null)) // coerce empty string/false/undefined to null
  }

  const onClearMinStay = () => {
    setFilters((filters) => filters.set('minimumStayNights', null))
  }

  const toggleHighFrequency = () => {
    setHighFrequency((hf) => !hf)
  }

  const toggleAutoBook = () => {
    setAutoBook((autoBook) => !autoBook)
  }

  return (
    <ConfirmationModal
      title="Edit Watch"
      confirmAction="Save"
      onConfirm={persistChanges}
      onRequestClose={onRequestClose}
    >
      <div className={styles.EditWatchModal}>
        <div className={styles.subhead}>
          Editing your watch for <strong>{site.name}</strong> {site.emoji}
        </div>
        <SettingsSection title="Dates (optional)">
          <Setting
            title="Watch Start Date"
            action={
              <ClearableDateInput
                onChangeDate={onChangeDate}
                onClearDate={onClearDate}
                filters={filters}
                filterKey="isAfterInclusive"
              />
            }
          >
            Only send notifications for availability on or after this date
          </Setting>

          <Setting
            title="Watch End Date"
            action={
              <ClearableDateInput
                onChangeDate={onChangeDate}
                onClearDate={onClearDate}
                filters={filters}
                filterKey="isBeforeInclusive"
              />
            }
          >
            Only send notifications for availability on or before this date
          </Setting>
        </SettingsSection>
        <SettingsSection title="Nights (optional)">
          <Setting
            title="Minimum Stay Length"
            action={
              <ClearableNumberInput
                onChange={onChangeMinStay}
                onClear={onClearMinStay}
                value={filters.get('minimumStayNights')}
              />
            }
          >
            Only send notifications for spans of availability of at least this
            many nights
          </Setting>
          <Setting
            title="Days of Week"
            action={
              <ClearableDaysOfWeekInput
                onChange={onChangeDaysOfWeek}
                onClear={onClearDaysOfWeek}
                value={filters.get('daysOfWeek')}
              />
            }
          >
            Filter availability by the day of week - useful, for example, if you
            only have weekends free
          </Setting>
        </SettingsSection>

        <AdminOnly>
          <SettingsSection title="Admin Options">
            <Setting
              title="Scan at High Frequency"
              action={
                <input
                  type="checkbox"
                  checked={highFrequency}
                  onChange={toggleHighFrequency}
                />
              }
            >
              Warning - may trigger rate limiting. Watch the logs.
            </Setting>
            {!supportsAutobook && (
              <Setting
                title="Auto-Book"
                action={<input type="checkbox" disabled checked={false} />}
              >
                Auto-Book is not yet supported for {site.scanType} scan types.
              </Setting>
            )}
            {supportsAutobook && (
              <Setting
                title="Auto-Book"
                action={
                  <input
                    type="checkbox"
                    checked={autoBook}
                    onChange={toggleAutoBook}
                  />
                }
              >
                Automatically add any new availabilities matching this watch to
                your cart on rec.gov.
              </Setting>
            )}
            {autoBook && (
              <Setting
                title="Group Size"
                action={
                  <input
                    type="number"
                    min="1"
                    max="6"
                    step="1"
                    value={groupSize}
                    onChange={(e) => setGroupSize(e.target.value)}
                  />
                }
              >
                Number of slots to reserve via auto-book
              </Setting>
            )}
          </SettingsSection>
        </AdminOnly>
      </div>
    </ConfirmationModal>
  )
}

const ClearableDateInput = ({
  onChangeDate,
  onClearDate,
  filters,
  filterKey,
}) => {
  return (
    <>
      <input
        type="date"
        value={filters.get(filterKey) || ''}
        onChange={onChangeDate(filterKey)}
      />{' '}
      <button onClick={onClearDate(filterKey)} className={styles.clearButton}>
        clear
      </button>
    </>
  )
}

const ClearableNumberInput = ({ onChange, onClear, value }) => {
  return (
    <>
      <input type="number" onChange={onChange} value={value || ''} />{' '}
      <button onClick={onClear} className={styles.clearButton}>
        clear
      </button>
    </>
  )
}

const isNonEmptyDays = (daysObj) =>
  daysObj && Object.values(daysObj).filter((v) => v).length > 0

const ClearableDaysOfWeekInput = ({ onChange, onClear, value }) => {
  const toggleDay = (day) => {
    const newVal = { ...value, [day]: !value?.[day] }
    if (isNonEmptyDays(newVal)) {
      onChange(newVal)
    } else {
      onClear()
    }
  }
  const DayButton = ({ day }) => {
    return (
      <button
        className={cx(styles.dayButton, value?.[day] && styles.isSelected)}
        onClick={() => toggleDay(day)}
      >
        {day}
      </button>
    )
  }

  return (
    <>
      <div className={styles.daysOfWeekInput}>
        <DayButton day="Su" />
        <DayButton day="Mo" />
        <DayButton day="Tu" />
        <DayButton day="We" />
        <DayButton day="Th" />
        <DayButton day="Fr" />
        <DayButton day="Sa" />
      </div>{' '}
      <button onClick={onClear} className={styles.clearButton}>
        clear
      </button>
    </>
  )
}

import React from 'react'
import styles from './CircleSpinner.module.scss'
import cx from 'classnames'

export class CircleSpinner extends React.PureComponent {
  render() {
    const { className, inline, ...props } = this.props
    return (
      <span
        className={cx(styles.CircleSpinner, inline && styles.inline, className)}
        {...props}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <g className={styles.group}>
            <circle
              className={styles.circle}
              strokeWidth="2"
              cx="16"
              cy="16"
              r="14"
              fill="none"
            ></circle>
            <circle
              className={styles.spin}
              strokeWidth="2"
              cx="16"
              cy="16"
              r="14"
              fill="none"
            ></circle>
          </g>
        </svg>
      </span>
    )
  }
}

import React from 'react'
import styles from './icons.module.css'
import cx from 'classnames'
import { ReactComponent as IosImageSvg } from 'assets/icon/ios-image.svg'
import { ReactComponent as IosPinSvg } from 'assets/icon/ios-pin.svg'
import { ReactComponent as IosPinOutlineSvg } from 'assets/icon/ios-pin-outline.svg'
import { ReactComponent as IosUmbrellaSvg } from 'assets/icon/ios-umbrella.svg'
import { ReactComponent as IosKeypadSvg } from 'assets/icon/ios-keypad.svg'
import { ReactComponent as IosExpandSvg } from 'assets/icon/ios-expand.svg'
import { ReactComponent as MapOutlineSvg } from 'assets/icon/ios-map-outline.svg'
import { ReactComponent as MoonOutlineSvg } from 'assets/icon/ios-moon-outline.svg'
import { ReactComponent as MoonSvg } from 'assets/icon/ios-moon.svg'
import { ReactComponent as SunnySvg } from 'assets/icon/ios-sunny.svg'
import { ReactComponent as SunnyOutlineSvg } from 'assets/icon/ios-sunny-outline.svg'
import { ReactComponent as PartlySunnyOutlineSvg } from 'assets/icon/ios-partly-sunny-outline.svg'
import { ReactComponent as PauseOutlineSvg } from 'assets/icon/ios-pause-outline.svg'
import { ReactComponent as PlayOutlineSvg } from 'assets/icon/ios-play-outline.svg'
import { ReactComponent as PauseSvg } from 'assets/icon/ios-pause.svg'
import { ReactComponent as MapSvg } from 'assets/icon/ios-map.svg'
import { ReactComponent as MapAltSvg } from 'assets/icon/map-alt.svg'
import { ReactComponent as PersonOutlineSvg } from 'assets/icon/ios-person-outline.svg'
import { ReactComponent as ExitOutlineSvg } from 'assets/icon/ios-exit-outline.svg'
import { ReactComponent as ExitSvg } from 'assets/icon/ios-exit.svg'
import { ReactComponent as HeadingSvg } from 'assets/icon/heading.svg'
import { ReactComponent as TextStyleSvg } from 'assets/icon/text-style.svg'
import { ReactComponent as SettingsOutlineSvg } from 'assets/icon/ios-settings-outline.svg'
import { ReactComponent as SwitchOutlineSvg } from 'assets/icon/ios-switch-outline.svg'
import { ReactComponent as SwitchSvg } from 'assets/icon/ios-switch.svg'
import { ReactComponent as OptionsOutlineSvg } from 'assets/icon/ios-options-outline.svg'
import { ReactComponent as OptionsSvg } from 'assets/icon/ios-options.svg'
import { ReactComponent as ArrowBackSvg } from 'assets/icon/ios-arrow-back.svg'
import { ReactComponent as ArrowDownSvg } from 'assets/icon/ios-arrow-down.svg'
import { ReactComponent as AddCircleOutlineSvg } from 'assets/icon/ios-add-circle-outline.svg'
import { ReactComponent as AddCircleSvg } from 'assets/icon/ios-add-circle.svg'
import { ReactComponent as CloseSvg } from 'assets/icon/ios-close.svg'
import { ReactComponent as CloseCircleSvg } from 'assets/icon/ios-close-circle-outline.svg'
import { ReactComponent as TrashOutlineSvg } from 'assets/icon/ios-trash-outline.svg'
import { ReactComponent as ShareOutlineSvg } from 'assets/icon/ios-share-outline.svg'
import { ReactComponent as CreateOutlineSvg } from 'assets/icon/ios-create-outline.svg'
import { ReactComponent as NavigateOutlineSvg } from 'assets/icon/ios-navigate-outline.svg'
import { ReactComponent as NotificationsSvg } from 'assets/icon/ios-notifications.svg'
import { ReactComponent as NotificationsOffSvg } from 'assets/icon/ios-notifications-off.svg'
import { ReactComponent as NotificationsOutlineSvg } from 'assets/icon/ios-notifications-outline.svg'
import { ReactComponent as NotificationsOffOutlineSvg } from 'assets/icon/ios-notifications-off-outline.svg'
import { ReactComponent as CheckmarkSvg } from 'assets/icon/ios-checkmark.svg'
import { ReactComponent as LeafOutlineSvg } from 'assets/icon/ios-leaf-outline.svg'
import { ReactComponent as GlassesSvg } from 'assets/icon/ios-glasses.svg'
import { ReactComponent as OpenOutlineSvg } from 'assets/icon/ios-open-outline.svg'
import { ReactComponent as CalendarOutlineSvg } from 'assets/icon/ios-calendar-outline.svg'
import { ReactComponent as CalendarSvg } from 'assets/icon/ios-calendar.svg'
import { ReactComponent as ColorWandSvg } from 'assets/icon/ios-color-wand.svg'
import { ReactComponent as CarOutlineSvg } from 'assets/icon/ios-car-outline.svg'
import { ReactComponent as RefreshCircleSvg } from 'assets/icon/ios-refresh-circle.svg'
import { ReactComponent as RefreshSvg } from 'assets/icon/ios-refresh.svg'
import { ReactComponent as CarSvg } from 'assets/icon/ios-car.svg'
import { ReactComponent as ClockOutlineSvg } from 'assets/icon/ios-clock-outline.svg'
import { ReactComponent as KeypadOutlineSvg } from 'assets/icon/ios-keypad-outline.svg'
import { ReactComponent as PaperOutlineSvg } from 'assets/icon/ios-paper-outline.svg'
import { ReactComponent as StatsOutlineSvg } from 'assets/icon/ios-stats-outline.svg'
import { ReactComponent as CameraOutlineSvg } from 'assets/icon/ios-camera-outline.svg'
import { ReactComponent as GlobeOutlineSvg } from 'assets/icon/ios-globe-outline.svg'
import { ReactComponent as LayersSvg } from 'assets/icon/layers.svg'

const i = (Component) => ({ className, inline, ...props }) => (
  <Component
    data-icon
    className={cx(styles.icon, inline && styles.inline, className)}
    {...props}
  />
)

export const NavigateOutlineIcon = i(NavigateOutlineSvg)
export const CameraOutlineIcon = i(CameraOutlineSvg)
export const GlobeOutlineIcon = i(GlobeOutlineSvg)
export const ImageIcon = i(IosImageSvg)
export const LayersIcon = i(LayersSvg)
export const RefreshCircleIcon = i(RefreshCircleSvg)
export const RefreshIcon = i(RefreshSvg)
export const TrashOutlineIcon = i(TrashOutlineSvg)
export const ShareOutlineIcon = i(ShareOutlineSvg)
export const CreateOutlineIcon = i(CreateOutlineSvg)
export const GlassesIcon = i(GlassesSvg)
export const PinIcon = i(IosPinSvg)
export const PinOutlineIcon = i(IosPinOutlineSvg)
export const UmbrellaIcon = i(IosUmbrellaSvg)
export const KeypadIcon = i(IosKeypadSvg)
export const ExpandIcon = i(IosExpandSvg)
export const HeadingIcon = i(HeadingSvg)
// TODO text-style.svg doesn't really match the rest of the icons
export const TextStyleIcon = i(TextStyleSvg)
export const PersonOutlineIcon = i(PersonOutlineSvg)
export const ExitOutlineIcon = i(ExitOutlineSvg)
export const ExitIcon = i(ExitSvg)
export const SettingsOutlineIcon = i(SettingsOutlineSvg)

export const MapOutlineIcon = i(MapOutlineSvg)
export const MoonOutlineIcon = i(MoonOutlineSvg)
export const MoonIcon = i(MoonSvg)
export const SunnyIcon = i(SunnySvg)
export const PartlySunnyOutlineIcon = i(PartlySunnyOutlineSvg)
export const PauseOutlineIcon = i(PauseOutlineSvg)
export const PlayOutlineIcon = i(PlayOutlineSvg)
export const PauseIcon = i(PauseSvg)
export const SunnyOutlineIcon = i(SunnyOutlineSvg)
export const MapIcon = i(MapSvg)
export const MapAltIcon = i(MapAltSvg)

export const SwitchOutlineIcon = i(SwitchOutlineSvg)
export const SwitchIcon = i(SwitchSvg)

export const OptionsOutlineIcon = i(OptionsOutlineSvg)
export const OptionsIcon = i(OptionsSvg)

export const AddCircleOutlineIcon = i(AddCircleOutlineSvg)
export const AddCircleIcon = i(AddCircleSvg)

export const ArrowBackIcon = i(ArrowBackSvg)
export const ArrowDownIcon = i(ArrowDownSvg)

export const CloseCircleOutlineIcon = i(CloseCircleSvg)
export const NotificationsIcon = i(NotificationsSvg)
export const NotificationsOffIcon = i(NotificationsOffSvg)
export const NotificationsOutlineIcon = i(NotificationsOutlineSvg)
export const NotificationsOffOutlineIcon = i(NotificationsOffOutlineSvg)
export const CheckmarkIcon = i(CheckmarkSvg)
export const LeafOutlineIcon = i(LeafOutlineSvg)
export const OpenOutlineIcon = i(OpenOutlineSvg)
export const CalendarOutlineIcon = i(CalendarOutlineSvg)
export const CalendarIcon = i(CalendarSvg)
export const ColorWandIcon = i(ColorWandSvg)
export const CarOutlineIcon = i(CarOutlineSvg)
export const CarIcon = i(CarSvg)
export const ClockOutlineIcon = i(ClockOutlineSvg)
export const KeypadOutlineIcon = i(KeypadOutlineSvg)
export const CloseIcon = i(CloseSvg)
export const PaperOutlineIcon = i(PaperOutlineSvg)
export const StatsOutlineIcon = i(StatsOutlineSvg)
